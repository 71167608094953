import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  setStartupName,
  setTagline,
  setWebsite,
  setOtherLinks,
  setDescription,
  setSelectedCategories,
} from "../../../redux/FormSlice";
import Select from "react-select";
import "./Form.css";

function Step1() {
  const startupName = useSelector((state) => state.form.startupName);
  const tagline = useSelector((state) => state.form.tagline);
  const website = useSelector((state) => state.form.website);
  const otherLinks = useSelector((state) => state.form.otherLinks);
  const description = useSelector((state) => state.form.description);
  const selectedCategories = useSelector(
    (state) => state.form.selectedCategories
  );
  const [selectedValuesForFirestore, setSelectedValuesForFirestore] = useState(
    []
  );

  const dispatch = useDispatch();

  const handleStartupNameChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 40) {
      dispatch(setStartupName(inputText));
    } else {
      dispatch(setStartupName(inputText.slice(0, 40)));
    }
  };

  const handleTaglineChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 60) {
      dispatch(setTagline(inputText));
    } else {
      dispatch(setTagline(inputText.slice(0, 60)));
    }
  };

  const handleWebsiteChange = (e) => {
    dispatch(setWebsite(e.target.value));
  };

  const handleOtherLinkChange = (index, value) => {
    const updatedLinks = [...otherLinks];
    updatedLinks[index] = value;
    dispatch(setOtherLinks(updatedLinks));
  };

  const handleDescriptionChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 250) {
      dispatch(setDescription(inputText));
    } else {
      dispatch(setDescription(inputText.slice(0, 250)));
    }
  };

  const handleSelectChange = (selected) => {
    const selectedValues = selected.map((option) => option.value);
    setSelectedCategories(selectedValues); // Extract the 'value' property
    setSelectedValuesForFirestore(selected); // Update the selected values for Firestore
    dispatch(setSelectedCategories(selectedValues)); // Update the selectedCategories state for display
  };

  const handleRemoveLinkField = (index) => {
    const updatedLinks = [...otherLinks];
    updatedLinks.splice(index, 1);
    dispatch(setOtherLinks(updatedLinks));
  };

  const handleAddLinkField = () => {
    dispatch(setOtherLinks([...otherLinks, "https://"])); // Dispatch the action to update the otherLinks state
  };

  const options = [
    { value: "E-commerce", label: "E-commerce" },
    { value: "FinTech", label: "FinTech" },
    { value: "EdTech", label: "EdTech" },
    { value: "AgriTech", label: "AgriTech" },
    { value: "HealthTech", label: "HealthTech" },
    { value: "AI, AR, & VR", label: "AI, AR, & VR" },
    { value: "Hardware", label: "Hardware" },
    { value: "Social", label: "Social" },
    { value: "Blockchain", label: "Blockchain" },
    { value: "Ride-Hailing", label: "Ride-Hailing" },
    { value: "PropTech", label: "ProTech" },
    { value: "ClimateTech", label: "ClimateTech" },
  ];

  return (
    <div>
      <div>
        {/* Startup Name */}
        <div className="mb-4">
          <div className="flex flex-row items-center justify-between">
            <div>
              <label
                htmlFor="last-name"
                className="block mb-2 text-[15px] font-medium text-gray-700"
              >
                Startup Name
              </label>
            </div>
            <div className="text-gray-400 text-[13px]">40 characters max</div>
          </div>
          <input
            type="text"
            id="startupName"
            name="startupName"
            placeholder="Simply your startup name"
            value={startupName}
            onChange={handleStartupNameChange}
            required
            className="w-full md:w-[300px] px-3 py-2 border rounded"
          />
        </div>

        {/* Tagline */}
        <div className="mb-4">
          <div className="flex flex-row items-center justify-between">
            <div>
              <label
                htmlFor="last-name"
                className="block mb-2 text-[15px] font-medium text-gray-700"
              >
                Tagline
              </label>
            </div>
            <div className="text-gray-400 text-[13px]">60 characters max</div>
          </div>
          <input
            type="text"
            id="startupName"
            name="startupName"
            placeholder="Your startup in a concise sentence"
            value={tagline}
            onChange={handleTaglineChange}
            required
            className="w-full md:w-[300px] px-3 py-2 border rounded"
          />
        </div>

        {/* Links */}
        <div className=" font-bold justify-start pt-5 pb-3 text-gray-900 text-[25px] md:text-[20px] header-font">
          <div>Links</div>
        </div>

        {/* Website */}
        <div className="mb-4">
          <div className="flex flex-row items-center justify-between">
            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-[15px] font-medium text-gray-700"
              >
                Website
              </label>
            </div>
          </div>
          <input
            type="url"
            id="website"
            name="website"
            value={website}
            onChange={handleWebsiteChange}
            required
            className="w-full md:w-[300px] px-3 py-2 border rounded"
          />
        </div>

        {otherLinks.map((link, index) => (
          <div key={index} className="mb-4">
            <div className="flex flex-row items-center justify-between">
              <div>
                <label
                  htmlFor={`otherLinks-${index}`}
                  className="block mb-2 text-[15px] font-medium"
                >
                  {index === 0 ? "Other" : `Other ${index}`}
                </label>
              </div>
              <div className="text-gray-400 text-[13px]">
                Google Play, App Store, Socials, etc
              </div>
            </div>
            <input
              type="url"
              id={`otherLinks-${index}`}
              name={`otherLinks-${index}`}
              value={link}
              onChange={(e) => handleOtherLinkChange(index, e.target.value)}
              required
              className="w-full md:w-[300px] px-3 py-2 border rounded"
            />

            {index > 0 && (
              <button
                type="button"
                onClick={() => handleRemoveLinkField(index)}
                className="text-red-500 text-[12px] underline ml-2"
              >
                Remove
              </button>
            )}
          </div>
        ))}
        <button
          type="button"
          onClick={handleAddLinkField}
          className="text-blue-500 text-[12px] font-semibold"
        >
          + Add other links
        </button>

        {/* Description */}
        <div className=" font-bold justify-start mt-6 pb-3 text-gray-900 text-[25px] md:text-[20px] header-font">
          <div>Description</div>
        </div>

        {/* Description */}
        <div className="mb-4">
          <div className="flex flex-row items-center justify-between">
            <div>
              <label
                htmlFor="website"
                className="block mb-2 text-[15px] font-medium text-gray-700"
              >
                Startup description
              </label>
            </div>
            <div className="text-gray-400 text-[13px]">250 characters max</div>
          </div>
          <textarea
            id="short-description"
            name="short-description"
            value={description}
            onChange={handleDescriptionChange}
            placeholder="What does your startup do?"
            required
            className="w-full px-3 py-2 border h-[130px] rounded"
          />
        </div>

        {/* Category */}
        <div className=" font-bold justify-start pt-1 pb-3 text-gray-900 text-[25px] md:text-[20px] header-font">
          <div>Industry</div>
        </div>
        <div className="mb-8">
          <div className="flex flex-row items-center justify-between">
            <div>
              <label
                htmlFor="last-name"
                className="block mb-2 text-[15px] font-medium text-gray-700"
              >
                Startup industry
              </label>
            </div>
            <div className="text-gray-400 text-[13px]">
              Select all that apply
            </div>
          </div>

          <div>
            <Select
              isMulti
              name="categories"
              options={options}
              value={selectedValuesForFirestore}
              onChange={handleSelectChange}
              className="basic-multi-select"
              classNamePrefix="select"
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Step1;
