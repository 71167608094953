import { createSlice } from "@reduxjs/toolkit";

const initialUserProfileState = {
  userName: "",
  headline: "",
  bio: "",
  displayPhoto: "",
};

const userProfileSlice = createSlice({
  name: "userProfile",
  initialState: initialUserProfileState,
  reducers: {
    setUserName: (state, action) => {
      state.userName = action.payload;
    },
    setHeadline: (state, action) => {
      state.headline = action.payload;
    },
    setBio: (state, action) => {
      state.bio = action.payload;
    },
    setDisplayPhoto: (state, action) => {
      state.displayPhoto = action.payload;
    },
  },
});

export const { setUserName, setHeadline, setBio, setDisplayPhoto } =
  userProfileSlice.actions;

export default userProfileSlice.reducer;
