import { Avatar, AvatarBadge } from "@chakra-ui/avatar";
import NavbarM from "../../components/NavBarM";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Skeleton, SkeletonCircle } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/AuthContext";

import { earliest, earliestUser } from "../../assets/images";

const UserProfile = () => {
  const [startups, setStartups] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state
  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const unsubscribe = getUserProfile();
      return () => unsubscribe();
    }
  }, [currentUser]);

  useEffect(() => {
    const unsubscribeAuthStateChange = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        // User is signed in, fetch startups
        const unsubscribeStartups = getStartups(user.uid);
        return () => {
          // Clean up the startups subscription when the component unmounts
          if (typeof unsubscribeStartups === "function") {
            unsubscribeStartups();
          }
        };
      } else {
        // User is signed out, handle it as needed
        setIsLoading(false);
      }
    });

    return () => {
      // Clean up the auth state change subscription when the component unmounts
      if (typeof unsubscribeAuthStateChange === "function") {
        unsubscribeAuthStateChange();
      }
    };
  }, []);

  useEffect(() => {
    console.log(userProfile);
  }, [userProfile]);

  function getUserProfile() {
    const usersCollRef = collection(db, "users");
    const q = query(usersCollRef, where("userId", "==", currentUser.uid));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedProfile = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Check if fetchedProfile array is empty
        if (fetchedProfile.length === 0) {
          // No user profile document found, set userProfile state with empty strings
          setUserProfile({
            userName: "",
            bio: "",
            // Add other fields if necessary
          });
        } else {
          // User profile document found, set userProfile state with the data
          setUserProfile(fetchedProfile[0]);
        }

        setIsLoading(false);
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  }

  function getStartups(currentUserId) {
    const startupsCollRef = collection(db, "startups");
    const q = query(startupsCollRef, where("userId", "==", `${currentUserId}`));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedStartups = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStartups(fetchedStartups);
        setIsLoading(false); // Update isLoading state when data is fetched
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false); // Update isLoading state in case of an error
      }
    );

    return unsubscribe;
  }

  return (
    <div>
      <div>
        <NavbarM />
      </div>
      <div>
        <div className="flex px-3 justify-center lg:pt-7">
          <div className=" w-full lg:h-[500px] lg:w-[800px] ">
            <div>
              <div className="flex flex-row justify-between items-center">
                <div className="text-gray-700 text-[20px] lg:text-[25px] font-bold">
                  My Profile
                </div>
                <div>
                  <Link to="/user-profile-edit">
                    <div className="px-4 py-3  hover:cursor-pointer  rounded">
                      <div className="flex flex-row items-center space-x-2">
                        <div className="">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-7 h-7"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                        </div>{" "}
                        <div className="text-[14px]">Edit</div>
                      </div>{" "}
                    </div>
                  </Link>
                </div>
              </div>

              <div className="lg:border-gray-200 border-gray-100  border-b-2 pt-2"></div>
              <div className="pt-6">
                <div className="flex lg:flex-row flex-col lg:space-x-9">
                  <div>
                    <div>
                      <div className="md:flex hidden">
                        {isLoading ? (
                          <SkeletonCircle size="40" />
                        ) : (
                          <Avatar
                            size="2xl"
                            src={
                              userProfile
                                ? userProfile.profilePhoto
                                : currentUser.photoURL
                            }
                            name="Tedor Lupaka"
                          >
                            <AvatarBadge boxSize="1em" bg="green.400" />
                          </Avatar>
                        )}
                      </div>
                    </div>
                    <div>
                      <div className="flex md:hidden">
                        {isLoading ? (
                          <SkeletonCircle height="60px" width="60px" />
                        ) : (
                          <Avatar
                            size="lg"
                            src={
                              userProfile
                                ? userProfile.profilePhoto
                                : currentUser.photoURL
                            }
                            name="Tedor Lupaka"
                          >
                            <AvatarBadge boxSize="1em" bg="green.400" />
                          </Avatar>
                        )}
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex flex-col">
                      <div className="pt-4">
                        {isLoading ? (
                          <Skeleton height="25px" width="200px" />
                        ) : (
                          <div className="text-[25px] font-sans font-medium">
                            {userProfile.userName !== ""
                              ? userProfile.userName
                              : currentUser.displayName}
                          </div>
                        )}
                      </div>
                      <div>
                        {isLoading ? (
                          // Loading skeleton
                          <div className="pt-4">
                            <Skeleton height="30px" width="330px" />
                          </div>
                        ) : userProfile.headline ? (
                          // If headline exists, display the headline
                          <div className="text-[16px] font-light md:text-[21px] font-sans text-gray-500 w-[330px]">
                            {userProfile.headline}
                          </div>
                        ) : (
                          // If headline doesn't exist, provide the option to add a short headline
                          <div className="pt-2">
                            <div className="py-3 px-3 text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                              <Link to="/user-profile-edit">
                                <span className="underline hover:cursor-pointer  hover:text-blue-500">
                                  {userProfile.headline === null
                                    ? "Add a short headline"
                                    : "Edit your short headline"}
                                </span>{" "}
                              </Link>
                              - e.g "CEO and founder at xxxxx"
                            </div>
                          </div>
                        )}
                      </div>
                      <div>
                        <div className="mt-2">
                          <div className="flex text-gray-700 flex-row space-x-3 items-center">
                            <div>0 followers</div>
                            <div>0 following</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="lg:pt-10 pt-5">
                <div className="font-semibold text-slate-900 text-[16px]">
                  Bio
                </div>
                <div className="pt-5">
                  {isLoading ? (
                    <div>
                      <Skeleton height="30px" width="330px" />
                    </div>
                  ) : userProfile.bio !== "" ? (
                    <div className="text-[16px] font-light md:text-[17px] font-sans text-gray-700">
                      {userProfile.bio}
                    </div>
                  ) : (
                    <div className="pt-2">
                      <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                        <Link to="/user-profile-edit">
                          {" "}
                          <span className="underline hover:cursor-pointer  hover:text-blue-500">
                            {" "}
                            Add a bio
                          </span>
                        </Link>{" "}
                        - let people know about your background or skills or
                        achievements
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className="pt-10">
                <div className="font-semibold text-slate-900 text-[16px]">
                  My Startups
                </div>
                <div className="pt-5">
                  {isLoading ? (
                    <div className="flex space-x-6 items-center flex-row">
                      {/* Display skeleton loaders when isLoading is true */}
                      {[1].map((index) => (
                        <div key={index}>
                          <div className="md:col-span-1 mb-6 md:mb-0 hover:cursor-pointer ">
                            <div>
                              <Skeleton height="65px" width="105px" />
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  ) : startups.length === 0 ? (
                    <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                      <span className="underline hover:cursor-pointer hover:text-blue-500">
                        {" "}
                        <Link to="/feature">Add your startup</Link>
                      </span>{" "}
                      - the world awaits what you're building
                    </div>
                  ) : (
                    <div className="flex space-x-6 lg:space-x-9 items-center flex-row">
                      {startups.map((startup) => (
                        <div
                          key={startup.id}
                          className=" md:mb-0 hover:cursor-pointer "
                        >
                          <img
                            src={startup.startupLogo}
                            className="h-[60px] w-[60px] lg:h-[80px] lg:w-[80px] rounded"
                            alt="Logo"
                          />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="pt-10">
                <div className="font-semibold text-slate-900 text-[16px]">
                  Badges
                </div>
              </div>

              <div>
                <div>
                  <div className="flex flex-col space-y-5">
                    <div>
                      <div className="flex pt-4  space-x-2 items-center flex-row">
                        <div>
                          <img
                            src={earliestUser}
                            className="h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded"
                            alt="Badge"
                          />
                        </div>
                        <div className="font-light text-gray-800 text-[13px]">
                          MasterUser
                        </div>
                      </div>
                    </div>
                    {startups.length !== 0 ? (
                      <div>
                        <div className="flex pt-4  space-x-2 items-center flex-row">
                          <div>
                            <img
                              src={earliest}
                              className="h-[40px] w-[40px] lg:h-[50px] lg:w-[50px] rounded"
                              alt="Badge"
                            />
                          </div>
                          <div className="font-light text-gray-800 text-[13px]">
                            EarlyStartup
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </div>
              <div className="pt-10">
                <div className="font-semibold text-slate-900 text-[16px]">
                  Joined Startups Zambia
                </div>
              </div>
              <div className="pt-5">
                {isLoading ? (
                  <div></div>
                ) : (
                  <div className="text-gray-500 text-[13px] flex justify-start pb-10">
                    &#x1F389; {currentUser.metadata.creationTime}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserProfile;
