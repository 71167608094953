
import AppRouter from './components/AppRouter';
import AuthContextProvider from './context/AuthContext';

function App() {
  return (
    <AuthContextProvider>
      <div>
        <AppRouter />
      </div>
    </AuthContextProvider>
  );
}

export default App;
