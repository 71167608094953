import React, { useRef, useState, useEffect } from "react";
import AvatarEditor from "react-avatar-editor";
import "./Form.css";
import { Skeleton } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { setCroppedImage } from "../../../redux/FormSlice";

const MyEditor = () => {
  const editor = useRef(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const croppedImage = useSelector((state) => state.form.croppedImage);
  const [cropData, setCropData] = useState({
    x: 0,
    y: 0,
    width: 250,
    height: 250,
    rotate: 0,
    scale: 1,
  });

  const dispatch = useDispatch();

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSelectedImage(file);
  };

  const handleSave = () => {
    if (editor.current) {
      const canvas = editor.current.getImageScaledToCanvas();
      const mimeType = selectedImage.type; // Determine MIME type based on format
      canvas.toBlob((blob) => {
        if (blob) {
          const croppedFile = new File([blob], selectedImage.name, {
            type: mimeType,
          });
          dispatch(setCroppedImage(croppedFile));
        }
      }, mimeType);
    }
  };

  const handleCropChange = (crop) => {
    setCropData(crop);
    // Remove handleSave() call from here
  };

  const handleSelectAnotherImage = () => {
    setSelectedImage(null);
    dispatch(setCroppedImage(null)); // Clear cropped image in the Redux store
  };

  useEffect(() => {
    if (selectedImage) {
      setTimeout(() => {
        handleSave();
      }, 100); // Delayed call to handleSave
    }
  }, [selectedImage]);

  useEffect(() => {
    if (selectedImage) {
      URL.revokeObjectURL(selectedImage); // Release URL when component unmounts
    }
  }, [selectedImage]);

  return (
    <div>
      {!selectedImage && (
        <label htmlFor="images" className="drop-container md:w-[350px]">
          <input
            type="file"
            id="images"
            accept="image/*"
            onChange={handleImageChange}
            className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
            required
          />
          <div className="flex flex-col justify-center items-center">
            <div>
              <span className=" text-blue-500 font-light hover:underline text-[13px]">
                Upload logo
              </span>
            </div>
            <div className="text-gray-400 text-[13px]">
              Preferred format: png, jpeg, or jpg{" "}
            </div>
            <div className="text-gray-400 text-[13px]">Max size: 5MB</div>
            <div>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="#909090"
                className="w-11 h-11"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                />
              </svg>
            </div>
          </div>
        </label>
      )}

      <div className="flex flex-row space-x-4">
        <div>
          {selectedImage && (
            <React.Fragment>
              <AvatarEditor
                ref={editor}
                image={selectedImage}
                width={110}
                height={110}
                scale={1}
                rotate={cropData.rotate}
                onCropChange={handleCropChange}
                onPositionChange={handleCropChange}
                className="h-[110px] w-[110px] rounded-md"
              />
              <div className="pt-3">
                <button
                  onClick={handleSelectAnotherImage}
                  className="bg-zinc-200 md:text-[14px] tracking-wider
                   px-4 py-3 justify-center text-[13px]
                    rounded items-center font-semibold flex"
                >
                  Reselect
                </button>
              </div>
            </React.Fragment>
          )}
        </div>
        <div>
          {croppedImage && (
            <div>
              <div>
                <label
                  htmlFor="website"
                  className="block mb-4 text-[15px] font-medium "
                >
                  Thumbnail preview
                </label>
              </div>

              <div className="flex flex-row space-x-2">
                <div>
                  <img
                    src={croppedImage ? URL.createObjectURL(croppedImage) : ""}
                    alt="Cropped"
                    className="w-[60px] h-[60px] rounded-md"
                  />
                </div>
                <div>
                  <div className="mb-4 pt-1">
                    <Skeleton height="10px" width="50px" />
                  </div>
                  <div className="mb-1">
                    <Skeleton height="10px" width="90px" />
                  </div>
                  <div className="mb-5">
                    <Skeleton height="10px" width="90px" />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default MyEditor;
