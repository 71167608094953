import { Avatar } from "@chakra-ui/avatar";
import {
  collection,
  doc,
  getDoc,
  onSnapshot,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { useEffect, useState } from "react";
import { db, storage } from "../../../firebase";
import { getDownloadURL, ref, uploadString } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { SkeletonCircle, Spinner } from "@chakra-ui/react";
import NavbarM from "../../../components/NavBarM";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../../../context/AuthContext";

const ProfileEdit = () => {
  const [userName, setUserName] = useState("");
  const [headline, setHeadline] = useState("");
  const [bio, setBio] = useState("");
  const [displayPhoto, setDisplayPhoto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);

  const { currentUser } = useAuth();

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const unsubscribe = getUserProfile();
      return () => unsubscribe();
    }
  }, [currentUser]);

  function getUserProfile() {
    const usersCollRef = collection(db, "users");
    const q = query(usersCollRef, where("userId", "==", currentUser.uid));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedProfile = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Check if fetchedProfile array is empty
        if (fetchedProfile.length === 0) {
          // No user profile document found, set userProfile state with empty strings
          setUserProfile({
            userName: "",
            bio: "",
            // Add other fields if necessary
          });
        } else {
          // User profile document found, set userProfile state with the data
          setUserProfile(fetchedProfile[0]);
        }

        setIsLoading(false);
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  }

  const navigate = useNavigate();

  const handleNameChange = (e) => {
    // Update the userName state when the input field changes
    setUserName(e.target.value);
  };

  const handleHeadlineChange = (e) => {
    // Update the userName state when the input field changes
    setHeadline(e.target.value);
  };

  const handleBioChange = (e) => {
    // Update the bio state when the textarea changes
    setBio(e.target.value);
  };

  const handlePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setDisplayPhoto(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    setLoading(true);
    try {
      const auth = getAuth();
      const userId = auth.currentUser.uid;

      // Prepare user data for Firestore
      const userData = {
        userId: userId,
      };

      // Conditionally add properties if they are not empty
      if (userName.trim() !== "") {
        userData.userName = userName.trim();
      }

      if (headline.trim() !== "") {
        userData.headline = headline.trim();
      }

      if (bio.trim() !== "") {
        userData.bio = bio.trim();
      }

      // Update or create the user profile document in Firestore
      const usersRef = doc(collection(db, "users"), userId);
      const userDoc = await getDoc(usersRef);

      if (userDoc.exists()) {
        // If user document exists, update it
        await updateDoc(usersRef, userData);
      } else {
        // If user document doesn't exist, create a new one
        await setDoc(usersRef, userData);
      }

      // Upload the photo to Firebase Storage if displayPhoto is different from the initialDisplayPhoto
      if (displayPhoto) {
        const storageRef = ref(storage, `profilePhotos/${userId}`);
        await uploadString(storageRef, displayPhoto, "data_url");
        const downloadURL = await getDownloadURL(storageRef);

        // Update the document in Firestore to set the image URL field.
        const docRef = doc(collection(db, "users"), userId);
        await updateDoc(docRef, { profilePhoto: downloadURL });
      }

      setLoading(false);

      navigate("/user-profile");

      // Optionally, you can close the edit modal or redirect the user to another page after saving changes.
    } catch (error) {
      // Handle errors, e.g., show error messages to the user
      console.error("Error saving changes:", error.message);
    }
  };

  return (
    <div>
      <div>
        <NavbarM />
      </div>
      <div className="flex px-3 justify-center lg:pt-10">
        <div className=" w-full lg:h-[500px] lg:w-[800px] ">
          <div className="flex flex-row justify-between items-center">
            <div className="text-gray-700 text-[20px] lg:text-[25px] font-bold">
              Edit Profile
            </div>
            <div>
              <Link to="/user-profile">
                <div className="px-4 py-3  hover:cursor-pointer  rounded">
                  <div className="flex flex-row items-center space-x-2">
                    {" "}
                    <div className="text-[14px]">Cancel</div>
                  </div>{" "}
                </div>
              </Link>
            </div>
          </div>
          <div className="lg:border-gray-200 border-gray-100  border-b-2 pt-2"></div>
          <div className="mt-4">
            <div className="flex flex-row items-center justify-between">
              <div>
                <label
                  htmlFor="last-name"
                  className="block mb-4 text-[16px] font-medium text-gray-700"
                >
                  Display photo
                </label>
              </div>
            </div>
            <div>
              <div className="">
                {isLoading ? (
                  <SkeletonCircle height="130px" width="130px" />
                ) : (
                  <Avatar
                    size="2xl"
                    src={
                      displayPhoto
                        ? displayPhoto
                        : userProfile
                        ? userProfile.profilePhoto
                        : currentUser.photoURL
                    }
                    name="Tedor Lupaka"
                  >
                    <div className="h-11 w-11  bg-white absolute ml-24 mt-[50px] rounded-full flex justify-center items-center">
                      <input
                        type="file"
                        id="images"
                        accept="image/*"
                        onChange={handlePhotoChange}
                        className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                        required
                      />
                      <div>
                        <div className="h-11 w-11 justify-center items-center flex bg-gray-100 rounded-full">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="black"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                            />
                          </svg>
                        </div>
                      </div>
                    </div>{" "}
                  </Avatar>
                )}
              </div>
            </div>
          </div>
          <div className="mb-4 pt-6">
            <div className="flex flex-row items-center justify-between">
              <div>
                <label
                  htmlFor="last-name"
                  className="block mb-2 text-[16px] font-medium text-gray-700"
                >
                  Full Name
                </label>
              </div>
            </div>
            <input
              type="text"
              id="userName"
              name="userName"
              value={userName}
              onChange={handleNameChange}
              required
              placeholder="Full Name"
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4 pt-6">
            <div className="flex flex-row items-center justify-between">
              <div>
                <label
                  htmlFor="last-name"
                  className="block mb-2 text-[16px] font-medium text-gray-700"
                >
                  Headline
                </label>
              </div>
            </div>
            <input
              type="text"
              id="userName"
              name="userName"
              value={headline}
              onChange={handleHeadlineChange}
              placeholder="E.g Founder and CEO at ABC"
              required
              className="w-full px-3 py-2 border rounded"
            />
          </div>
          <div className="mb-4 pt-6">
            <div className="flex flex-row items-center justify-between">
              <div>
                <label
                  htmlFor="website"
                  className="block mb-2 text-[16px] font-medium text-gray-700"
                >
                  Bio
                </label>
              </div>
            </div>
            <textarea
              id="short-description"
              name="short-description"
              value={bio}
              placeholder="Let people know about your background or skills or
              achievements"
              onChange={handleBioChange}
              required
              className="px-3 py-2 border w-full h-[130px] rounded"
            />
          </div>
          <div>
            <div className="flex justify-end mb-5 lg:pb-9">
              {loading ? (
                <button onClick={handleSaveChanges}>
                  <div className="px-3 py-3 bg-[#4169E1] border-2 border-[#4169E1] w-[155px] text-[13.5px] font-medium text-white flex justify-center items-center rounded text-center">
                    <div className="flex space-x-2 flex-row ">
                      <div>
                        <div className="mr-2">
                          <Spinner size="md" color="white" />
                        </div>
                      </div>
                      <div>Saving</div>
                    </div>
                  </div>
                </button>
              ) : (
                <button onClick={handleSaveChanges}>
                  <div className="px-3 py-3 bg-[#4169E1] border-2 border-[#4169E1] w-[155px] text-[13.5px] font-medium text-white flex justify-center items-center rounded text-center">
                    <div>Save Changes</div>
                  </div>
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileEdit;
