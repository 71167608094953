import { motion } from "framer-motion";
import "./index.css";

const BackdropModal = ({ children, onClick }) => {
  return (
    <motion.div
      className="backdrop-startup-modal z-50"
      onClick={onClick}
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="lg:mt-7"> {children}</div>
    </motion.div>
  );
};

export default BackdropModal;
