// Import the functions you need from the SDKs you need
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD0oBaE_R15ucMRimNPk-5NI_9X72xzwyA",
  authDomain: "startupszambia-be338.firebaseapp.com",
  projectId: "startupszambia-be338",
  storageBucket: "startupszambia-be338.appspot.com",
  messagingSenderId: "1021127824003",
  appId: "1:1021127824003:web:c8c75c2caa710b9c3ec1b6",
  measurementId: "G-8Q4GFB1PHD",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
