import React from "react";
import { Link } from "react-router-dom";

function ButtonOnboard() {
  return (
    <div>
      <Link to="/feature">
        <div className="px-3 py-3 bg-[#4169E1] text-white space-x-2 flex flex-row justify-center items-center rounded text-center font-medium">
          <div>Get started</div>
          <div className="pt-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="w-4 h-4"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </Link>
    </div>
  );
}

export default ButtonOnboard;
