import { motion } from "framer-motion";
import "./index.css";
import BackdropModal from "../StartupDetailsBackdrop";

const dropIn = {
  hidden: {
    opacity: 0, // Modal is hidden (fully transparent)
  },
  visible: {
    opacity: 1, // Modal is visible (fully opaque)
  },
  exit: {
    opacity: 0, // Modal is hidden again when exiting (fully transparent)
  },
};

const StartupModal = ({ handleClose, text }) => {
  return (
    <BackdropModal onClick={handleClose}>
      <motion.div
        className="startup-modal z-50 pt-5"
        onClick={(e) => e.stopPropagation()}
        variants={dropIn}
        initial="hidden"
        animate="visible"
        exit="exit"
      >
        {text}
      </motion.div>
    </BackdropModal>
  );
};

export default StartupModal;
