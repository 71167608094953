import NavbarM from "../../components/NavBarM";

import StartupSlider from "./StartupSlider";

const StartupProfile = () => {
  return (
    <div>
      <div className="">
        <NavbarM />
      </div>

      <div className="pt-3">
        <StartupSlider />
      </div>
    </div>
  );
};

export default StartupProfile;
