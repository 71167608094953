import { useRef, useState } from "react";
import "./index.css";
import Select from "react-select";
import { Tooltip } from "@chakra-ui/react";
import { motion } from "framer-motion";
import { db, storage } from "../../firebase";
import { collection, doc, updateDoc } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";

function Overview({ startup }) {
  const textareaVariants = {
    hidden: { opacity: 0, height: 0 },
    visible: { opacity: 1, height: "auto", transition: { duration: 1 } },
    exit: { opacity: 0, height: 0, transition: { duration: 1 } },
  };

  const [editDescription, setEditDescription] = useState(false);
  const [editStage, setEditStage] = useState(false);
  const [editSnapshot, setEditSnapshot] = useState(false);
  const options = [
    { value: "Pre-Seed", label: "Pre-Seed" },
    { value: "Seed", label: "Seed" },
    { value: "Series A", label: "Series A" },
    { value: "Growth", label: "Growth" },
    { value: "Late", label: "Late" },
  ];
  const [isUploading, setIsUploading] = useState(false);
  const [description, setDescription] = useState(startup.description);
  const [snapshotPhoto, setSnapshotPhoto] = useState("");
  const [imageChanged, setImageChanged] = useState(false);
  const [stage, setStage] = useState("");

  const handleDescriptionChange = (e) => {
    const inputText = e.target.value;
    if (inputText.length <= 250) {
      setDescription(inputText);
    } else {
      setDescription(inputText.slice(0, 250));
    }
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    setSnapshotPhoto(file);
    setImageChanged(true);
    setEditSnapshot(false);
  };

  const handleSelectChange = (selected) => {
    const selectedValue = selected.value; // Extract the 'value' property from the selected option
    setStage(selectedValue);
  };

  const updateStartup = (docRef, updatedStartup) => {
    return updateDoc(docRef, updatedStartup);
  };

  const handleSaveChanges = async () => {
    const updatedStartup = {};

    if (description.trim() !== "") {
      updatedStartup.description = description.trim();
    }

    if (stage !== "") {
      updatedStartup.stage = stage;
    }

    if (snapshotPhoto !== "") {
      updatedStartup.snapshotPhoto = snapshotPhoto;
    }

    if (Object.keys(updatedStartup).length > 0) {
      const startupCollRef = collection(db, "startups");
      const startupDocRef = doc(startupCollRef, startup.startupId);

      if (snapshotPhoto) {
        setIsUploading(true);

        const storageRef = ref(storage, "snapshotPhotos/" + snapshotPhoto.name);
        const uploadTask = uploadBytesResumable(storageRef, snapshotPhoto);

        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Track the upload progress here if needed
          },
          (error) => {
            console.log(error);
            setIsUploading(false);
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref)
              .then((downloadURL) => {
                updatedStartup.snapshotPhoto = downloadURL;
                updateStartup(startupDocRef, updatedStartup)
                  .then(() => {
                    console.log("startup updated successfully!");
                    setEditDescription(false);
                    setEditStage(false);
                    setImageChanged(false);
                  })
                  .catch((error) => {
                    console.log("Error updating startup:", error);
                  });
              })
              .catch((error) => {
                console.log(error);
                setIsUploading(false);
              });
          }
        );
      } else {
        updateStartup(startupDocRef, updatedStartup)
          .then(() => {
            console.log("startup updated successfully!");
            setEditDescription(false);
            setEditStage(false);
            setImageChanged(false);
          })
          .catch((error) => {
            console.log("Error updating startup:", error);
          });
      }
    } else {
      setEditDescription(false);
      setEditStage(false);
      setImageChanged(false);
    }
  };

  return (
    <div>
      {/* Startup Description*/}
      <div>
        <div className="flex flex-row lg:space-x-3 items-center justify-between lg:justify-normal">
          <div>
            {" "}
            <div className="font-bold lg:text-[18px]">
              What is {startup.startupName}?
            </div>
          </div>
          <div>
            {editDescription ? (
              <div></div>
            ) : (
              <div className="flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg">
                <div>
                  {" "}
                  <Tooltip label="Edit" placement="bottom">
                    <div
                      onClick={() => setEditDescription(true)}
                      className="hover:cursor-pointer  "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        {editDescription ? (
          <motion.div
            className="pt-3"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={textareaVariants}
          >
            <textarea
              id="short-description"
              name="short-description"
              value={description}
              onChange={handleDescriptionChange}
              placeholder="What does your startup do?"
              required
              className="w-full px-3 py-2 border h-[130px] rounded"
            />
            <div className="flex flex-row  justify-end pt-5 space-x-4">
              <div>
                <Tooltip label="Save changes" placement="bottom">
                  <div
                    onClick={handleSaveChanges}
                    className="hover:cursor-pointer flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip label="Cancel" placement="bottom">
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => setEditDescription(false)}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </Tooltip>
              </div>
            </div>
          </motion.div>
        ) : (
          <div>
            {startup.description !== undefined &&
            startup.description.trim() !== "" ? (
              <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
                {startup.description}
              </div>
            ) : (
              <div>
                <div className=" flex justify-center lg:justify-start pt-3">
                  <div>
                    <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                      <span className="underline hover:cursor-pointer hover:text-blue-500">
                        {" "}
                        Add your startup description
                      </span>{" "}
                      - let the community know who you are and what you do
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Startup Stage*/}
      <div>
        <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
          {" "}
          <div className="font-bold">Stage</div>
          <div>
            {editStage ? (
              <div></div>
            ) : (
              <div className="flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg">
                <div>
                  {" "}
                  <Tooltip label="Edit" placement="bottom">
                    <div
                      onClick={() => setEditStage(true)}
                      className="hover:cursor-pointer  "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>

        {editStage ? (
          <motion.div
            className="pt-3"
            initial="hidden"
            animate="visible"
            exit="exit"
            variants={textareaVariants}
          >
            <Select
              className="basic-single"
              classNamePrefix="select"
              defaultValue={options[0]}
              onChange={handleSelectChange}
              isSearchable
              name="stages"
              options={options}
            />
            <div className="flex flex-row  justify-end pt-5 space-x-4">
              <div>
                <Tooltip label="Save changes" placement="bottom">
                  <div
                    onClick={handleSaveChanges}
                    className="hover:cursor-pointer flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M4.5 12.75l6 6 9-13.5"
                      />
                    </svg>
                  </div>
                </Tooltip>
              </div>
              <div>
                <Tooltip label="Cancel" placement="bottom">
                  <div
                    className="hover:cursor-pointer"
                    onClick={() => setEditStage(false)}
                  >
                    {" "}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </div>
                </Tooltip>
              </div>
            </div>
          </motion.div>
        ) : (
          <div>
            {startup.stage !== undefined && startup.stage !== "" ? (
              <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
                {startup.stage}
              </div>
            ) : (
              <div>
                <div className=" flex justify-center lg:justify-start pt-3">
                  <div>
                    <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                      <span
                        onClick={() => setEditStage(true)}
                        className="underline hover:cursor-pointer hover:text-blue-500"
                      >
                        {" "}
                        Add your stage
                      </span>{" "}
                      - let people know how far along you are (i.e pre-seed,
                      Seed, Series A...)
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </div>

      {/* Startup Snapshot*/}
      <div>
        <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
          {" "}
          <div className="font-bold">Snapshot</div>
          <div>
            {imageChanged || editSnapshot ? (
              <div></div>
            ) : (
              <div className="flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg">
                <div>
                  {" "}
                  <Tooltip label="Edit" placement="bottom">
                    <div
                      onClick={() => setEditSnapshot(true)}
                      className="hover:cursor-pointer  "
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-5 h-5"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                        />
                      </svg>
                    </div>
                  </Tooltip>
                </div>
              </div>
            )}
          </div>
        </div>
        {editSnapshot ? (
          <div>
            <label htmlFor="images" className="drop-container lg:w-[690px]">
              <input
                type="file"
                id="images"
                accept="image/*"
                onChange={handleImageChange}
                className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                required
              />
              <div className="flex flex-col justify-center items-center">
                <div>
                  <span className=" text-blue-500 font-light hover:underline text-[13px]">
                    Upload Image
                  </span>
                </div>
                <div className="text-gray-400 text-center px-7 text-[14px]">
                  An image or screenshot of your product/service - a preview to
                  the community{" "}
                </div>
                <div className="text-gray-400 text-[13px]">Max size: 5MB</div>
                <div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="#909090"
                    className="w-11 h-11"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                    />
                  </svg>
                </div>
              </div>
            </label>
            <div>
              <Tooltip label="Cancel" placement="bottom">
                <div
                  className="hover:cursor-pointer"
                  onClick={() => setEditSnapshot(false)}
                >
                  {" "}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <div>
            <div className=" flex justify-center lg:justify-start pt-3">
              {imageChanged ? (
                <div>
                  <div>
                    <img
                      src={URL.createObjectURL(snapshotPhoto)}
                      className=" rounded"
                      alt="snapshot"
                    />
                  </div>
                  <div className="flex flex-row  justify-end pt-5 space-x-4">
                    <div>
                      <Tooltip label="Save changes" placement="bottom">
                        <div
                          onClick={handleSaveChanges}
                          className="hover:cursor-pointer flex justify-center items-center h-7 w-7 hover:bg-slate-200 rounded-lg"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M4.5 12.75l6 6 9-13.5"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                    </div>
                    <div>
                      <Tooltip label="Cancel" placement="bottom">
                        <div
                          className="hover:cursor-pointer"
                          onClick={() => setImageChanged(false)}
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M6 18L18 6M6 6l12 12"
                            />
                          </svg>
                        </div>
                      </Tooltip>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {startup.snapshotPhoto !== undefined &&
                  startup.snapshotPhoto !== "" ? (
                    <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
                      <img
                        src={startup.snapshotPhoto}
                        className="max-h-[500px] rounded"
                        alt="Logo"
                      />
                    </div>
                  ) : (
                    <div>
                      <label
                        htmlFor="images"
                        className="drop-container lg:w-[690px]"
                      >
                        <input
                          type="file"
                          id="images"
                          accept="image/*"
                          onChange={handleImageChange}
                          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
                          required
                        />
                        <div className="flex flex-col justify-center items-center">
                          <div>
                            <span className=" text-blue-500 font-light hover:underline text-[13px]">
                              Upload Image
                            </span>
                          </div>
                          <div className="text-gray-400 text-center px-7 text-[14px]">
                            An image or screenshot of your product/service - a
                            preview to the community{" "}
                          </div>
                          <div className="text-gray-400 text-[13px]">
                            Max size: 5MB
                          </div>
                          <div>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth={1.5}
                              stroke="#909090"
                              className="w-11 h-11"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
                              />
                            </svg>
                          </div>
                        </div>
                      </label>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        )}
      </div>

      {/*Startup Feedback*/}
      <div>
        <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
          {" "}
          <div className="font-bold">Feedback and Comments</div>
        </div>
        <div className=" flex justify-center lg:justify-start pt-4">
          <div>
            <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
              Feedback and comments from the community will soon appear here,
              keep building!
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Overview;
