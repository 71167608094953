import React, { useState } from "react";
import { useSelector } from "react-redux";
import { getAuth } from "firebase/auth";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import {
  collection,
  addDoc,
  doc,
  serverTimestamp,
  updateDoc,
  setDoc,
} from "firebase/firestore";
import Step1 from "./form/Step1";
import Step2 from "./form/Step2";
import Step3 from "./form/Step3";
import { storage, db } from "../../firebase";
import NavbarM from "../../components/NavBarM";
import { useNavigate } from "react-router-dom";
import { Spinner, useToast } from "@chakra-ui/react";

function Feature() {
  const [currentStep, setCurrentStep] = useState(1);
  const [loading, setLoading] = useState(false); // Track the current step

  const form = useSelector((state) => state.form);

  const navigate = useNavigate();
  const toast = useToast();
  const startupName = form.startupName;
  const tagline = form.tagline;
  const website = form.website;
  const otherLinks = form.otherLinks;
  const description = form.description;
  const categories = form.selectedCategories;
  const startupImages = form.selectedImages;
  const startupLogo = form.croppedImage;
  const press = form.selectedOption;

  const handleNextStep = () => {
    if (currentStep === 1) {
      // Add validation logic for Step 1
      // If validation fails, return without updating the state
      // Otherwise, proceed to the next step
      setCurrentStep(currentStep + 1);
    }

    if (currentStep === 2) {
      // Add validation logic for Step 2
      // If validation fails, return without updating the state
      // Otherwise, proceed to the next step
      setCurrentStep(currentStep + 1);
    }
  };

  const handlePreviousStep = () => {
    setCurrentStep(currentStep - 1);
  };

  const auth = getAuth();

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    console.log(startupLogo);

    console.log(startupImages);

    function capitalizeFirstLetter(str) {
      if (typeof str !== "string") {
        return str;
      }

      return str
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    }

    // Upload the startup logo
    const logoStorageRef = ref(storage, "startupLogos/" + startupLogo.name);
    const logoSnapshot = await uploadBytesResumable(
      logoStorageRef,
      startupLogo
    );
    const logoDownloadURL = await getDownloadURL(logoSnapshot.ref);

    // Create a startup document in the "startups" collection
    const newStartupRef = await addDoc(collection(db, "startups"), {
      startupName: capitalizeFirstLetter(startupName.trim()),
      tagline: capitalizeFirstLetter(tagline.trim()),
      website: website.trim(),
      otherLinks: otherLinks,
      description: description.trim(),
      selectedCategories: categories,
      selectedOption: press,
      startupLogo: logoDownloadURL,
      userId: auth.currentUser.uid,
      timeStamp: serverTimestamp(),
    });

    // Upload and store images
    const imagePromises = startupImages.map(async (image) => {
      const imageStorageRef = ref(storage, "startupImages/" + image.name);
      const imageSnapshot = await uploadBytesResumable(imageStorageRef, image);
      const imageURL = await getDownloadURL(imageSnapshot.ref);
      return imageURL;
    });

    navigate("/");

    const imageDownloadURLs = await Promise.all(imagePromises);
    const id = newStartupRef.id;
    // Update the startup document with the uploaded image URLs
    const startupDocRef = doc(collection(db, "startups"), newStartupRef.id);
    await updateDoc(startupDocRef, {
      startupImages: imageDownloadURLs,
      startupId: id,
    });

    console.log("Form submitted!");
    toast({
      description: "Startup added successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom",
      containerStyle: {
        width: "350px",
      },
    });
    navigate("/");
    // navigate('/profile'); // Assuming you have a routing mechanism in place
    // toast({ ... }); // Assuming you have a toast notification mechanism in place
    setLoading(false);
  };

  return (
    <div className="bg-white">
      <NavbarM />

      <div className="px-3">
        {currentStep === 1 && (
          <div className="lg:mx-[400px]">
            <div className="flex pt-4 space-x-2 font-bold justify-start text-gray-900 text-[29px] lg:text-[29px] header-font">
              <div>About your startup</div>
            </div>
            <p className="text-[13px] lg:text-[14px] mb-4  pt-3 text-gray-700">
              The world has its eyes set on Zambia
            </p>
            <div className="pt-5">
              <Step1 />
              <div className="mb-20">
                <button onClick={handleNextStep}>
                  <div className="px-3 py-3 bg-[#4169E1] w-[220px] text-[13.5px] font-medium text-white space-x-2 flex justify-center items-center rounded text-center">
                    <div>Next: logos & media</div>
                  </div>
                </button>
              </div>
            </div>
          </div>
        )}

        {currentStep === 2 && (
          <div className="mt-4 lg:mx-[400px]">
            <div className="">
              <button onClick={handlePreviousStep}>
                <div className="py-3 flex-row text-[13.5px] space-x-2 font-medium text-gray-600 flex items-center rounded text-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                  <div>Previous</div>
                </div>
              </button>
            </div>
            <div className="mb-3 mt-2">
              <hr></hr>
            </div>
            <div className="flex space-x-2 font-bold justify-start text-gray-900 text-[25px] md:text-[18px] header-font">
              <div>Display logo</div>
            </div>
            <div className="pt-3">
              <Step2 />
              <div className="mb-20 mt-5 flex flex-row justify-between space-x-4">
                <div>
                  <button onClick={handlePreviousStep}>
                    <div className="px-3 py-3 w-[155px] flex-row text-[13.5px] space-x-2 font-medium text-gray-600 flex justify-center items-center rounded text-center">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-3 h-3"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 19.5L8.25 12l7.5-7.5"
                          />
                        </svg>
                      </div>
                      <div>Previous</div>
                    </div>
                  </button>
                </div>
                <div>
                  <button onClick={handleNextStep}>
                    <div className="px-3 py-3 bg-[#4169E1] border-2 border-[#4169E1] w-[155px] text-[13.5px] font-medium text-white flex justify-center items-center rounded text-center">
                      <div>Next: additionals</div>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        {currentStep === 3 && (
          <div className="mt-4 lg:mx-[400px]">
            <div className="">
              <button onClick={handlePreviousStep}>
                <div className="py-3 flex-row text-[13.5px] space-x-2 font-medium text-gray-600 flex justify-center items-center rounded text-center">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-3 h-3"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M15.75 19.5L8.25 12l7.5-7.5"
                      />
                    </svg>
                  </div>
                  <div>Previous</div>
                </div>
              </button>
            </div>
            <div className="mb-3 mt-2">
              <hr></hr>
            </div>
            <div className="flex space-x-2 font-bold justify-start text-gray-900 text-[25px] md:text-[18px] header-font">
              <div>Press</div>
            </div>
            <div className="pt-5">
              {/* Render the content for Step 3 */}
              <div>
                <Step3 />
              </div>
            </div>

            <div className="mb-20 mt-5 flex flex-row justify-between space-x-4">
              <div>
                <button onClick={handlePreviousStep}>
                  <div className="px-3 py-3 w-[155px] flex-row text-[13.5px] space-x-2 font-medium text-gray-600 flex justify-center items-center rounded text-center">
                    <div>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-3 h-3"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M15.75 19.5L8.25 12l7.5-7.5"
                        />
                      </svg>
                    </div>
                    <div>Previous</div>
                  </div>
                </button>
              </div>
              <div>
                {loading ? (
                  <button onClick={handleFormSubmit}>
                    <div className="px-3 py-3 bg-[#4169E1] border-2 border-[#4169E1] w-[155px] text-[13.5px] font-medium text-white flex justify-center items-center rounded text-center">
                      <div className="flex space-x-2 flex-row ">
                        <div>
                          <div className="mr-2">
                            <Spinner size="md" color="white" />
                          </div>
                        </div>
                        <div>Submitting</div>
                      </div>
                    </div>
                  </button>
                ) : (
                  <button onClick={handleFormSubmit}>
                    <div className="px-3 py-3 bg-[#4169E1] border-2 border-[#4169E1] w-[155px] text-[13.5px] font-medium text-white flex justify-center items-center rounded text-center">
                      <div>Submit</div>
                    </div>
                  </button>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Feature;
