import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useLocation,
} from "react-router-dom";
import Home from "../pages/home/Home";
import { useAuth } from "../context/AuthContext";
import Login from "../pages/login/Login";
import Onboard from "../pages/listStartup/Onboard";
import Feature from "../pages/listStartup/Feature";
import StartupProfile from "../pages/startupProfile";
import UserProfile from "../pages/userProfile";
import ProfileEdit from "../pages/userProfile/ProfileEdit";

function ProtectedRoute({ path, ...props }) {
  const { currentUser } = useAuth();
  const location = useLocation();

  if (!currentUser) {
    // User is not logged in, redirect to login page
    return <Navigate to="/login" />;
  }

  if (
    currentUser &&
    (path === "/login" || path === "/register" || path === "/forgot-password")
  ) {
    // User is already logged in, redirect to profile page
    return <Navigate to={location.state?.from ?? "/profile"} />;
  }

  // Render the requested route
  return <Route {...props} />;
}

function AppRouter() {
  return (
    <Router>
      <div>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/onboard" element={<Onboard />} />
          <Route path="/feature" element={<Feature />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/user-profile-edit" element={<ProfileEdit />} />
          <Route path="/startup-profile" element={<StartupProfile />} />
        </Routes>
      </div>
    </Router>
  );
}

export default AppRouter;
