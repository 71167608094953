import Button from "./Button";

function Hero() {
  return (
    <div className="md:mt-[30px] sm:px-6 px-[10px] flex justify-start flex-col -z-0">
      <div className="lg:hidden">
        {" "}
        <div className="font-bold flex justify-start   md:mb-[15px] text-[20px] md:text-5xl text-center">
          Startups Zambia
        </div>
        <p className="text-gray-500 text-[15px] pt-1">
          Discover the Zambian startup ecosystem
        </p>
      </div>
      <div>
        <div className="flex justify-start md:pt-[20px]">
          <Button />
        </div>
      </div>
    </div>
  );
}

export default Hero;
