import { createSlice } from "@reduxjs/toolkit";

const initialFormState = {
  startupName: "",
  tagline: "",
  website: "https://",
  otherLinks: ["https://"],
  description: "",
  selectedCategories: [],
  selectedImages: [],
  croppedImage: null,
  selectedOption: "",
};

const formSlice = createSlice({
  name: "form",
  initialState: initialFormState,
  reducers: {
    setStartupName: (state, action) => {
      state.startupName = action.payload;
    },
    setTagline: (state, action) => {
      state.tagline = action.payload;
    },
    setWebsite: (state, action) => {
      state.website = action.payload;
    },
    setOtherLinks: (state, action) => {
      state.otherLinks = action.payload;
    },
    setDescription: (state, action) => {
      state.description = action.payload;
    },
    setSelectedCategories: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setSelectedImages: (state, action) => {
      state.selectedImages = action.payload;
    },
    setCroppedImage: (state, action) => {
      state.croppedImage = action.payload;
    },
    setSelectedOption: (state, action) => {
      state.selectedOption = action.payload;
    },
    removeSelectedImage: (state, action) => {
      const index = action.payload;
      state.selectedImages.splice(index, 1);
    },
  },
});

export const {
  setStartupName,
  setTagline,
  setWebsite,
  setOtherLinks,
  setDescription,
  setSelectedCategories,
  setSelectedImages,
  setCroppedImage,
  setSelectedOption,
  removeSelectedImage,
} = formSlice.actions;

export default formSlice.reducer;
