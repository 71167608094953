import React from "react";
import MyEditor from "./ImageC";
import MultiImage from "./MultiImage";

function Step2() {
  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-6">
        <div>
          <label
            htmlFor="website"
            className="block  text-[15px] font-medium text-gray-700"
          >
            Startup logo
          </label>
        </div>
        <div className="text-gray-400 text-[13px]">
          Recommended size: 512×512
        </div>
      </div>
      <div className="mb-5">
        <MyEditor />
      </div>

      <div className=" font-bold justify-start pt-3 pb-3 text-gray-900 text-[25px] md:text-[18px] header-font">
        <div>Product</div>
      </div>
      <div>
        <label
          htmlFor="website"
          className="block  text-[15px] font-medium text-gray-700"
        >
          Product images/screenshots
        </label>
      </div>
      <div>
        <MultiImage />
      </div>
    </div>
  );
}

export default Step2;
