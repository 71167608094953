import React, { useEffect, useState } from "react";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  query,
  serverTimestamp,
  updateDoc,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";
import { Skeleton, useToast } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import Modal from "../../components/Modal";
import { AnimatePresence } from "framer-motion";
import Login from "../login/Login";
import StartupModal from "../../components/StartupModal";
import StartupDetails from "../../components/StartupDetails";

function AllCategories() {
  const [modalOpen, setModalOpen] = useState(false);
  const [startupModalOpen, setStartupModalOpen] = useState(false);
  const [startups, setStartups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [openModalId, setOpenModalId] = useState(null);
  const [upvotedStartups, setUpvotedStartups] = useState({});
  const [startupDetails, setStartupDetails] = useState(null);
  const { currentUser } = getAuth();

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  const closeStartupModal = () => setStartupModalOpen(false);

  useEffect(() => {
    const unsubscribe = getStartups();

    return () => {
      // Ensure that unsubscribe is a function before calling it
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, []);

  async function getStartups() {
    const startupsCollRef = collection(db, "startups");

    const unsubscribe = onSnapshot(
      startupsCollRef,
      async (snapshot) => {
        const fetchedStartups = await Promise.all(
          snapshot.docs.map(async (doc) => {
            const startupData = doc.data();
            const upvotesCollectionRef = collection(doc.ref, "upvotes");

            // Subscribe to changes in the upvotes subcollection for each startup
            const upvoteUnsubscribe = onSnapshot(
              upvotesCollectionRef,
              (upvoteSnapshot) => {
                const upvoteCount = upvoteSnapshot.size;
                // Update the local state to reflect the upvote count
                setStartups((prevStartups) => {
                  return prevStartups.map((startup) => {
                    if (startup.id === doc.id) {
                      return {
                        ...startup,
                        upvoteCount: upvoteCount,
                      };
                    }
                    return startup;
                  });
                });
              }
            );

            return {
              id: doc.id,
              ...startupData,
              upvoteCount: 0, // Initialize upvote count as 0
              upvoteUnsubscribe, // Store the upvote unsubscribe function
            };
          })
        );

        setStartups(fetchedStartups);
        setIsLoading(false);
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false);
      }
    );

    return unsubscribe;
  }

  async function toggleUpvote(startupId) {
    if (currentUser) {
      try {
        const auth = getAuth();
        const userId = auth.currentUser.uid;
        const startupDocRef = doc(collection(db, "startups"), startupId);
        const upvotesCollectionRef = collection(startupDocRef, "upvotes");
        const upvoteQuerySnapshot = await getDocs(
          query(upvotesCollectionRef, where("userId", "==", userId))
        );

        if (!upvoteQuerySnapshot.empty) {
          // User has previously upvoted this startup
          setUpvotedStartups((prevUpvotedStartups) => ({
            ...prevUpvotedStartups,
            [startupId]: false,
          }));
          const upvoteDocRef = upvoteQuerySnapshot.docs[0].ref;

          // Delete the upvote document
          await deleteDoc(upvoteDocRef);

          // Update the local state to reflect the unupvote
        } else {
          setUpvotedStartups((prevUpvotedStartups) => ({
            ...prevUpvotedStartups,
            [startupId]: true,
          }));
          // User hasn't upvoted yet; implement upvoting logic here.
          const docData = {
            userId: userId,
            timestamp: serverTimestamp(),
            documentId: null, // Initialize the documentId as null
          };

          // Add a new upvote document with user ID and initial documentId field
          const docRef = await addDoc(upvotesCollectionRef, docData);
          const documentId = docRef.id;

          // Update the document to set the documentId field
          await updateDoc(docRef, {
            documentId: documentId,
          });

          // Update the local state to reflect the upvote
        }
      } catch (error) {
        console.error("Error toggling upvote:", error);
      }
    } else {
      modalOpen ? close() : open();
    }
  }

  function onStartupClick(startup) {
    // Open the modal only if the clicked startup ID matches the desired startup ID
    if (startup.id === openModalId) {
      setStartupDetails(startup);
      openStartupModal();
    } else {
      // If the clicked startup ID doesn't match openModalId, update openModalId and set startupDetails
      setOpenModalId(startup.id);
      setStartupDetails(startup);
      openStartupModal();
    }
  }

  const openStartupModal = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
    setStartupModalOpen(true);
  };

  return (
    <div>
      <div className="flex justify-start flex-col">
        <div className="pt-4 lg:ml-[32px] md:pt-7 font-semibold text-2xl md:text-3xl">
          Is our first <span className="animate-pulse">&#x1f984;</span> here?
        </div>
        <div>
          <div className="flex items-center justify-center">
            <div className="pt-4 md:pt-4">
              {isLoading ? (
                <div className="md:grid  md:grid-cols-3 md:gap-[100px]">
                  {/* Display skeleton loaders when isLoading is true */}
                  {[1, 2, 3].map((index) => (
                    <div key={index}>
                      <div className="md:col-span-1 mb-6 md:mb-0 hover:cursor-pointer ">
                        <div className="flex justify-start  flex-row space-x-2">
                          <div>
                            <Skeleton height="80px" width="80px" />
                          </div>
                          <div>
                            <Skeleton height="20px" width="100px" mt="2" />
                            <Skeleton height="20px" width="240px" mt="2" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                <div className="md:grid md:grid-cols-3 md:gap-[58px]">
                  {startups.map((startup) => (
                    <div
                      key={startup.id}
                      className="md:col-span-1 md:mb-0 hover:cursor-pointer "
                    >
                      <div className="flex flex-row items-center justify-between space-x-2 lg:space-x-0">
                        <div
                          onClick={() => {
                            setOpenModalId(startup.id);
                            onStartupClick(startup);
                          }}
                          className="md:mr-3 mr-3 pt-2  hover:animate-pulse"
                        >
                          <img
                            src={startup.startupLogo}
                            className="h-[60px] w-[60px] lg:h-[70px] lg:w-[70px] rounded"
                            alt="Logo"
                          />
                        </div>
                        <div className="md:pr-4 w-[220px] pr-2 md:w-[240px]">
                          <div
                            onClick={() => {
                              setOpenModalId(startup.id);
                              onStartupClick(startup);
                            }}
                            className="flex mt-3 flex-col"
                          >
                            <div className="font-semibold text-[13px] md:text-[14px]">
                              {startup.startupName}
                            </div>
                            <div className="text-[11.5px] md:text-[14px] font-sans text-gray-500 ">
                              {startup.tagline}
                            </div>
                            <div>
                              <div>
                                <div className="">
                                  <div className="flex">
                                    {startup.selectedCategories.map(
                                      (selectedCategory, index) => (
                                        <div className="flex flex-row space-x-2">
                                          <div
                                            key={index}
                                            className="pb-2 pr-2"
                                          >
                                            <div>
                                              <div className="py-[4px] noSelect  text-[11px] md:text-[11px] inline-block select-none text-gray-700 ">
                                                #{selectedCategory}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="pr-3">
                          <div
                            onClick={() => toggleUpvote(startup.id)}
                            className=" md:px-4  pt-4 pb-6 inset-y-3 md:border flex flex-col justify-center items-center border-b-2  md:w-[60px] md:rounded-md"
                          >
                            <div>
                              <button
                                // Pass the startup ID to the toggleUpvote function
                                className={`${
                                  upvotedStartups[startup.id]
                                    ? "text-[#4169E1]"
                                    : "text-black"
                                } hover:text-[#4169E1] cursor-pointer`}
                              >
                                <svg
                                  className="m-auto"
                                  width="14"
                                  height="13"
                                  viewBox="0 0 26 22"
                                  fill={
                                    upvotedStartups[startup.id]
                                      ? "#4169E1"
                                      : "#323232"
                                  }
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.134 0.499999C12.5189 -0.166668 13.4811 -0.166667 13.866 0.5L25.1244 20C25.5093 20.6667 25.0281 21.5 24.2583 21.5H1.74167C0.971868 21.5 0.490744 20.6667 0.875644 20L12.134 0.499999Z"
                                    fill={
                                      upvotedStartups[startup.id]
                                        ? "#4169E1"
                                        : "#323232"
                                    }
                                  />
                                </svg>
                              </button>
                            </div>
                            <div>
                              <span className="text-f13 font-semibold mt-1 block">
                                {/* Display the upvote count here */}
                                {startup.upvoteCount}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <AnimatePresence
                        initial={false}
                        mode="wait"
                        onExitComplete={() => null}
                      >
                        {" "}
                        {modalOpen && (
                          <Modal
                            modalOpen={modalOpen}
                            handleClose={close}
                            text={
                              <Login
                                signUpHeaderText="Sign Up to Upvote"
                                signUpBodyText="The Zambian entreprenurial ecosystem - startups, founders, investors,..."
                                close={close}
                              />
                            }
                          />
                        )}
                      </AnimatePresence>

                      <AnimatePresence
                        initial={false}
                        mode="wait"
                        onExitComplete={() => null}
                      >
                        {" "}
                        {startupModalOpen && (
                          <StartupModal
                            startupModalOpen={startupModalOpen}
                            handleClose={closeStartupModal}
                            text={<StartupDetails startup={startupDetails} />}
                          />
                        )}
                      </AnimatePresence>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AllCategories;
