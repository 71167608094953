import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { useEffect, useRef, useState } from "react";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { Skeleton } from "@chakra-ui/react";

function SimilarStartups({ similarCategories, startupId }) {
  const [similarStartups, setSimilarStartups] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = getStartups();

    return () => {
      // Ensure that unsubscribe is a function before calling it
      if (typeof unsubscribe === "function") {
        unsubscribe();
      }
    };
  }, [similarCategories]);

  function getStartups() {
    const startupsCollRef = collection(db, "startups");

    let q;
    if (similarCategories.length > 0) {
      // If similarCategories is not empty, filter startups by categories
      q = query(
        startupsCollRef,
        where("selectedCategories", "array-contains-any", similarCategories)
      );
    } else {
      // If similarCategories is empty, fetch all startups
      q = startupsCollRef;
    }
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedStartups = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Shuffle the fetched startups array
        const shuffledStartups = fetchedStartups.sort(
          () => Math.random() - 0.5
        );

        // Slice the first 3 startups for display
        const randomStartups = shuffledStartups.slice(0, 3);

        setSimilarStartups(randomStartups);
        setIsLoading(false); // Update isLoading state when data is fetched
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false); // Update isLoading state in case of an error
      }
    );

    return unsubscribe;
  }

  function onStartupClick() {
    // Open the modal only if the clicked startup ID matches the desired startup ID
  }

  return (
    <div>
      <div>
        <div className="flex justify-start flex-col">
          <div>
            <div className="flex items-center justify-start">
              <div className="pt-4 md:pt-4">
                {isLoading ? (
                  <div className="">
                    {/* Display skeleton loaders when isLoading is true */}
                    {[1, 2, 3].map((index) => (
                      <div key={index}>
                        <div className="md:col-span-1 mb-6 md:mb-0 hover:cursor-pointer ">
                          <div className="flex justify-start  flex-row space-x-2">
                            <div>
                              <Skeleton height="80px" width="80px" />
                            </div>
                            <div>
                              <Skeleton height="20px" width="100px" mt="2" />
                              <Skeleton height="20px" width="240px" mt="2" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <div>
                    <div>
                      {similarStartups.map((similarStartup) => (
                        <div
                          key={similarStartup.id}
                          className=" hover:cursor-pointer pb-3"
                        >
                          <div className="flex flex-row items-center justify-between space-x-2 lg:space-x-0">
                            <div
                              onClick={() => {
                                onStartupClick(similarStartup);
                              }}
                              className="md:mr-3 mr-3 pt-2  hover:animate-pulse"
                            >
                              <img
                                src={similarStartup.startupLogo}
                                className="h-[50px] w-[50px] lg:h-[50px] lg:w-[50px] rounded"
                                alt="Logo"
                              />
                            </div>
                            <div className="md:pr-4 w-[220px] pr-2 md:w-[240px]">
                              <div
                                onClick={() => {
                                  onStartupClick(similarStartup);
                                }}
                                className="flex mt-3 flex-col"
                              >
                                <div className="font-semibold text-[13px] md:text-[13.5px]">
                                  {similarStartup.startupName}
                                </div>
                                <div className="text-[11.5px] font-normal md:text-[12.5px] font-sans text-gray-500 ">
                                  {similarStartup.tagline}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SimilarStartups;
