import { useToast } from "@chakra-ui/react";
import { getAuth } from "firebase/auth";
import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import Login from "../login/Login";

function Button() {
  const navigate = useNavigate();
  const toast = useToast();
  const [modalOpen, setModalOpen] = useState(false);

  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  function handleClick() {
    const { currentUser } = getAuth();
    console.log(currentUser);
    if (currentUser) {
      navigate("/feature");
    } else {
      modalOpen ? close() : open();
    }
  }

  return (
    <div onClick={handleClick} className="pt-3 pb-2">
      <div className="px-4 py-3 bg-black rounded-full hover:cursor-pointer text-white hover:bg-gray-900 flex flex-row justify-center items-center text-center font-medium">
        <div className="">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 6v12m6-6H6"
            />
          </svg>
        </div>
        <div className="text-[14px]">Add Startup</div>
      </div>
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        {" "}
        {modalOpen && (
          <Modal
            modalOpen={modalOpen}
            handleClose={close}
            text={
              <Login
                signUpHeaderText="Sign In/Up"
                signUpBodyText="We can't wait to see you and your startup change the world"
                close={close}
              />
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
}

export default Button;
