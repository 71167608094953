import React from "react";
import ButtonOnboard from "./ButtonOnboard";
import { logo, onboard } from "../../assets/images";

function Onboard() {
  return (
    <div className="px-3">
      <div className="pt-6">
        <img src={logo} className="h-[45px] w-[45px] rounded-lg" alt="Logo" />
      </div>
      <div>
        <h1 className="font-bold pb-2 flex  justify-start items-center text-gray-900 pt-5 text-[35px] md:text-[18px] header-font">
          The next tech hotbed...&#128640;
        </h1>
        <p className="text-[15px] pr-11 pb-1 pt-2 text-gray-700">
          Help the world discover what you're building.
        </p>
      </div>
      <div className="pt-5">
        <ButtonOnboard />
      </div>
      <div className="flex justify-center pt-10 pb-5">
        <img src={onboard} className="h-[185px] w-[185px]" alt="Logo" />
      </div>
    </div>
  );
}

export default Onboard;
