import React from "react";
import "./Form.css";
import { useSelector, useDispatch } from "react-redux";
import {
  setSelectedImages,
  removeSelectedImage,
} from "../../../redux/FormSlice";

const MultiImage = () => {
  const selectedImages = useSelector((state) => state.form.selectedImages);
  const dispatch = useDispatch();

  const handleImageSelect = (e) => {
    const files = Array.from(e.target.files);
    const selectedFiles = files.filter((file) =>
      file.type.startsWith("image/")
    );
    dispatch(setSelectedImages(selectedFiles));
  };

  const handleImageRemove = (index) => {
    dispatch(removeSelectedImage(index));
  };

  return (
    <div className="mt-5">
      <label
        htmlFor="image-input"
        className="relative w-full h-48 border-2 border-dashed rounded cursor-pointer flex items-center justify-center"
      >
        <input
          id="image-input"
          type="file"
          accept="image/*"
          multiple
          className="absolute inset-0 w-full h-full opacity-0 cursor-pointer"
          onChange={handleImageSelect}
        />
        <div className="flex flex-col justify-center items-center">
          <div>
            <span className="text-blue-500 font-light hover:underline text-[13px]">
              Upload images
            </span>
          </div>
          <div className="text-gray-400 text-[13px]">
            Preferred format: png, jpeg, or jpg
          </div>
          <div className="text-gray-400 text-[13px]">Max sizes: 5MB</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="#909090"
              className="w-11 h-11"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 15.75l5.159-5.159a2.25 2.25 0 013.182 0l5.159 5.159m-1.5-1.5l1.409-1.409a2.25 2.25 0 013.182 0l2.909 2.909m-18 3.75h16.5a1.5 1.5 0 001.5-1.5V6a1.5 1.5 0 00-1.5-1.5H3.75A1.5 1.5 0 002.25 6v12a1.5 1.5 0 001.5 1.5zm10.5-11.25h.008v.008h-.008V8.25zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
              />
            </svg>
          </div>
          <div className="text-gray-400 text-[13px]">At least one</div>
        </div>
      </label>

      <div className="mt-4 grid grid-cols-4 gap-4">
        {selectedImages.map((image, index) => (
          <div key={index} className="relative">
            <img
              src={URL.createObjectURL(image)}
              alt={`Selected Image ${index + 1}`}
              className="h-16 w-24 object-cover rounded"
            />
            <button
              className="absolute top-0 right-0 p-1 h-4 w-4 bg-red-500 text-white rounded"
              onClick={() => handleImageRemove(index)}
            >
              x
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default MultiImage;
