function ProductService({ startup }) {
  return (
    <div>
      <div>
        <div className="flex flex-row lg:space-x-3 justify-between lg:justify-normal">
          <div>
            {" "}
            <div className="font-bold lg:text-[18px]">Gallery</div>
          </div>
          <div className="pr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </div>
        </div>
        <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
          {/* <div
      className="mt-3"
      style={{
        height: "300px",
        display: "flex",
        width: "100%",
        overflowX: "hidden",
      }}
      ref={boxRef}
    >
      <motion.div
        style={{
          display: "flex",
          gap: "25px",
          transform: `translateX(-${activeImage * 65}px)`, // Adjust the width of the image container accordingly
        }}
        drag="x"
        dragConstraints={boxRef}
      >
        {startup.startupImages.map((startupImage) => (
          <div key={shortid.generate()}>
            <div
              style={{ position: "relative" }}
             
            
            >
              <img
                className={
                  isMouseOver === image.label
                    ? "rounded-sm shadow-md shadow-blue-200"
                    : "rounded-sm shadow-sm"
                }
                key={startup.id}
                src={startupImage}
                style={{
                  minWidth: isMouseOver === image.label ? "220px" : "210px",
                  height: isMouseOver === image.label ? "290px" : "280px",
                  objectFit: "cover",
                }}
              />
              
            </div>
          </div>
        ))}
      </motion.div>
    </div>*/}
        </div>
      </div>
      <div></div>
    </div>
  );
}

export default ProductService;
