import React from "react";
import Hero from "./Hero";
import CategorySlider from "./CategorySlider";
import NavbarM from "../../components/NavBarM";
import MenuSlider from "./MenuSlider";
function Home() {
  return (
    <div>
      <NavbarM />
      <Hero />
      <div className="lg:hidden">
        <MenuSlider />
      </div>
      <div className="hidden lg:flex">
        <div>
          <CategorySlider />
        </div>
      </div>
    </div>
  );
}

export default Home;
