import { ExternalLinkIcon } from "@chakra-ui/icons";
import { MenuItem } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";

function Links({ startup }) {
  return (
    <div>
      <div>
        <div className="flex flex-row lg:space-x-3 justify-between lg:justify-normal">
          <div>
            {" "}
            <div className="font-bold lg:text-[18px]">Website</div>
          </div>
          <div className="pr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </div>
        </div>
        <div className="pt-3 hover:underline text-slate-600 pr-2 lg:text-[16px] text-[14px]">
          <a
            href={`${startup.website}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {startup.website}
          </a>
        </div>
      </div>
      <div>
        <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
          <div>
            {" "}
            <div className="font-bold lg:text-[18px]">Socials</div>
          </div>
          <div className="pr-5">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
              />
            </svg>
          </div>
        </div>
        <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
          {startup.otherLinks.map((otherLink, index) => (
            <div>
              <div className="hover:underline" key={index}>
                <a
                  href={`${otherLink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {" "}
                  {otherLink}
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Links;
