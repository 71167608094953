import { useRef, useState } from "react";
import "./index.css";
import Overview from "./Overview";
import ProductService from "./ProductService";
import Links from "./Links";

function DetailsSlider({ startup }) {
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);
  const tabs = [
    {
      id: 0,
      label: "Overview",
    },
    {
      id: 1,
      label: "Product/Service",
    },
    {
      id: 2,
      label: "Links",
    },
    {
      id: 3,
      label: "Funding",
    },
    {
      id: 4,
      label: "Team",
    },
    {
      id: 5,
      label: "Milestones",
    },
  ];
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleArrowClick = (direction) => {
    const numTabs = tabs.length;
    let scrollAmount = 0;

    if (direction === "left") {
      scrollAmount = -sliderRef.current.clientWidth;
    } else if (direction === "right") {
      scrollAmount = sliderRef.current.clientWidth;
    }

    sliderRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="lg:w-[700px] w-full">
      <div className="flex justify-start items-center">
        <div className="conta my-auto w-screen lg:w-[700px]  relative">
          <div className="">
            <ul
              className="flex bg-white md:items-center noSelect outline-none flex-row cursor-pointer text-gray-900 font-sans space-x-[19px] font-medium whitespace-nowrap md:space-x-[16px] py-[9px] m-0 "
              ref={sliderRef}
            >
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  className={`bg-white pb-2 ${tab.id === 0 ? "" : ""} `}
                >
                  {tab.icon && tab.icon}
                  <div className="pt-1">
                    {" "}
                    <div
                      href="#"
                      className={`py-[4px] lg:py-[8px] noSelect px-[15px] md:font-medium font-normal  md:px-[24px] text-[13px] md:text-[14px] inline-block select-none ${
                        tab.id === activeTab
                          ? " text-white rounded-full border-2  border-black bg-black"
                          : " text-gray-500 rounded-full hover:bg-gray-50 bg-blue-50 lg:bg-purple-100"
                      }`}
                      onClick={() => handleTabClick(tab.id)}
                    >
                      {tab.label}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="absolute right-arrow-gradient h-full w-[50px] md:w-[100px] right-0 top-0 hidden md:flex justify-end items-center md:hover:bg-gray-900"
            onClick={() => handleArrowClick("right")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="black"
              className="w-[24px] h-[24px] cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
      <div>
        <div className=" overflow-x-hidden pt-4 ">
          {activeTab === 0 && <Overview startup={startup} />}
          {activeTab === 1 && <ProductService startup={startup} />}
          {activeTab === 2 && <Links startup={startup} />}
        </div>
      </div>
    </div>
  );
}

export default DetailsSlider;
