import { useState, useRef } from "react";
import "./Home.css";
import AllCategories from "./AllCategories";
import SelectedCategory from "./SelectedCategory";
import CategorySlider from "./CategorySlider";

function MenuSlider() {
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);
  const tabs = [
    {
      id: 0,
      label: "Startups",
    },
    {
      id: 1,
      label: "Community",
      descriptions: "Tutors",
    },
    {
      id: 2,
      label: "Events",
      descriptions: "Programmers",
    },
    {
      id: 3,
      label: "Accelerators",
      descriptions: "Photographers",
    },
    {
      id: 4,
      label: "Incubators",
      descriptions: "Designers",
    },

    {
      id: 5,
      label: "Jobs",
      descriptions: "Financial Analysts",
    },
  ];

  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleArrowClick = (direction) => {
    const numTabs = tabs.length;
    let scrollAmount = 0;

    if (direction === "left") {
      scrollAmount = -sliderRef.current.clientWidth;
    } else if (direction === "right") {
      scrollAmount = sliderRef.current.clientWidth;
    }

    sliderRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-white ">
      <div className="flex justify-center items-center">
        <div className="conta mx-[16px] my-auto w-screen md:w-[1400px] relative md:pl-[60px]">
          <div
            className="absolute md:left-0 left-arrow-gradient hidden h-full w-[50px] md:w-[40px] top-0 md:flex items-center"
            onClick={() => handleArrowClick("left")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="black"
              className="w-[24px] h-[24px] cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 19.5L8.25 12l7.5-7.5"
              />
            </svg>
          </div>
          <div className="">
            <ul
              className="flex pl-2 bg-white md:items-center md:justify-center noSelect outline-none flex-row cursor-pointer text-gray-900 font-sans space-x-[9px] font-medium whitespace-nowrap md:space-x-[16px] py-[9px]  md:px-[40px] m-0 "
              ref={sliderRef}
            >
              {tabs.map((tab) => (
                <li
                  key={tab.id}
                  className={`bg-white pb-2 ${
                    tab.id === 0 ? "lg:ml-[254px]" : ""
                  } `}
                >
                  {tab.icon && tab.icon}
                  <div className="pt-1">
                    {" "}
                    <div
                      href="#"
                      className={`py-[4px] lg:py-[8px] noSelect px-[15px] md:font-medium   md:px-[24px] text-[13px] md:text-[14px] inline-block select-none ${
                        tab.id === activeTab
                          ? " text-gray-700 font-extrabold border-black border-b-2 "
                          : " text-gray-700 font-normal"
                      }`}
                      onClick={() => handleTabClick(tab.id)}
                    >
                      {tab.label}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div
            className="absolute right-arrow-gradient h-full w-[50px] md:w-[100px] right-0 top-0 hidden md:flex justify-end items-center md:hover:bg-gray-900"
            onClick={() => handleArrowClick("right")}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="black"
              className="w-[24px] h-[24px] cursor-pointer"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className="md:px-[45px] overflow-x-hidden">
        {activeTab === 0 ? (
          <CategorySlider />
        ) : (
          <div className="flex justify-center items-center pt-10">
            Coming soon
          </div>
        )}
      </div>
    </div>
  );
}

export default MenuSlider;
