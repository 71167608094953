import { useEffect, useState } from "react";
import { useToast, Spinner, Button, Box, Flex, Image } from "@chakra-ui/react";
import { useAuth } from "../../context/AuthContext";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { facebook, github, google, logo, twitter } from "../../assets/images";
import { addDoc, collection, doc, setDoc } from "firebase/firestore";
import { db } from "../../firebase";

function Login({ signUpHeaderText, signUpBodyText, close }) {
  const toast = useToast();
  const { currentUser } = useAuth();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false); // Track loading state
  const [loading1, setLoading1] = useState(false); // Track loading state
  const [loading2, setLoading2] = useState(false); // Track loading state
  const [loading3, setLoading3] = useState(false); // Track loading state

  const {
    login,
    signInWithGoogle,
    signInWithFacebook,
    signInWithGithub,
    signInWithTwitter,
  } = useAuth();

  const handleLoginSuccess = () => {
    console.log("Login successful!");
    navigate("/");
    toast({
      description: "Signed In successfully",
      status: "success",
      duration: 2000,
      isClosable: true,
      position: "bottom",
      containerStyle: {
        width: "350px",
      },
    });
  };

  const handleLoginFailure = (error) => {
    console.log(error);
    toast({
      description: "Error, please try again or use another method",
      status: "error",
      duration: 4000,
      isClosable: true,
      position: "bottom",
      containerStyle: {
        width: "350px",
      },
    });
  };

  const handleGoogleSignIn = async () => {
    setLoading(true); // Set loading state to true
    try {
      const user = await signInWithGoogle();
      console.log(user);
      handleLoginSuccess(); // Pass the user object to createUser function
      close();
    } catch (error) {
      console.log(error);
      handleLoginFailure(error);
    } finally {
      setLoading(false);
      // Set loading state to false
    }
  };

  const handleFacebookSignIn = () => {
    setLoading1(true); // Set loading state to true
    signInWithFacebook()
      .then((user) => {
        console.log(user);
        handleLoginSuccess();
      })
      .catch((error) => {
        console.log(error);
        handleLoginFailure(error);
      })
      .finally(() => {
        setLoading1(false); // Set loading state to false
      });
  };

  const handleGithubSignIn = () => {
    setLoading2(true); // Set loading state to true
    signInWithGithub()
      .then((user) => {
        console.log(user);
        handleLoginSuccess();
      })
      .catch((error) => {
        console.log(error);
        handleLoginFailure(error);
      })
      .finally(() => {
        setLoading2(false); // Set loading state to false
      });
  };

  const handleTwitterSignIn = () => {
    setLoading3(true); // Set loading state to true
    signInWithTwitter()
      .then((user) => {
        console.log(user);
        handleLoginSuccess();
      })
      .catch((error) => {
        console.log(error);
        handleLoginFailure(error);
      })
      .finally(() => {
        setLoading3(false); // Set loading state to false
      });
  };

  return (
    <div className="bg-white pt-10 pb-10">
      <div className="flex justify-center items-center">
        <div className="">
          <div
            className="flex  flex-col
           justify-center items-center"
          >
            <div className="text-[17px] lg:text-[18px]">&#128640;</div>
            <div>
              <div className="flex  space-x-2 font-normal justify-start text-gray-800 text-[23.5px] lg:text-[22px] header-font">
                <div>{signUpHeaderText}</div>
              </div>
            </div>
            <div>
              <div className="text-gray-400 md:text-gray-600 lg:px-[100px] text-[16px] md:text-[20px] pt-1 flex text-center justify-center">
                {signUpBodyText}
              </div>
            </div>
            <div>
              <div>
                <button onClick={handleGoogleSignIn} disabled={loading}>
                  <div className="flex items-center mt-4 flex-row border-gray-200 px-4 lg:px-5 py-2 rounded-full border-2 ">
                    <div>
                      {loading ? (
                        <div className="mr-2">
                          <Spinner size="md" color="orange.500" />
                        </div>
                      ) : (
                        <img
                          src={google}
                          className="h-[35px] w-[35px] rounded-lg"
                          alt="Logo"
                        />
                      )}
                    </div>
                    <div>
                      <p className="text-[14px]">Continue with Google</p>
                    </div>
                  </div>
                </button>
              </div>
            </div>
            <div>
              <div className="flex-row hidden space-x-5 justify-center items-center">
                <div>
                  <div className="h-[48.5px] w-[48.5px] border-2 flex justify-center items-center border-gray-500 rounded-lg">
                    <button
                      onClick={handleGoogleSignIn}
                      disabled={loading} // Disable button while loading
                      className=""
                    >
                      {loading ? (
                        <div>
                          <Spinner size="md" color="orange.500" />
                        </div>
                      ) : (
                        <img
                          src={google}
                          className="h-[45px] w-[45px] rounded-lg"
                          alt="Logo"
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div className="">
                  <div>
                    <div className="hidden justify-center items-center">
                      <button
                        onClick={handleFacebookSignIn}
                        disabled={loading1} // Disable button while loading
                        className=""
                      >
                        {loading1 ? (
                          <div>
                            <Spinner size="md" color="orange.500" />
                          </div>
                        ) : (
                          <img
                            src={facebook}
                            className="h-[45px] w-[45px] rounded-lg"
                            alt="Logo"
                          />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="hidden justify-center items-center">
                    <button
                      onClick={handleTwitterSignIn}
                      disabled={loading3} // Disable button while loading
                      className=""
                    >
                      {loading3 ? (
                        <div>
                          <Spinner size="md" color="orange.500" />
                        </div>
                      ) : (
                        <img
                          src={twitter}
                          className="h-[45px] w-[45px] rounded-lg"
                          alt="Logo"
                        />
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <div className="hidden justify-center items-center">
                    <button
                      onClick={handleGithubSignIn}
                      disabled={loading2} // Disable button while loading
                      className=""
                    >
                      {loading2 ? (
                        <div>
                          <Spinner size="md" color="orange.500" />
                        </div>
                      ) : (
                        <img
                          src={github}
                          className="h-[45px] w-[45px] rounded-lg"
                          alt="Logo"
                        />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
