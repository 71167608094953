import {
  Link,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import "./index.css";
import { ChevronDownIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import Overview from "../../pages/startupProfile/Overview";
import ProductService from "../../pages/startupProfile/ProductService";
import Links from "../../pages/startupProfile/Links";
import { useRef, useState } from "react";
import OverviewD from "./OverviewD";
import SimilarStartups from "../../pages/startupProfile/SimilarStartups";

const StartupDetails = ({ startup }) => {
  const {
    startupLogo,
    startupName,
    description,
    selectedCategories,
    stage,
    startupId,
    tagline,
    upvoteCount,
    website,
    otherLinks /* other properties */,
  } = startup;

  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);
  const tabs = [
    {
      id: 0,
      label: "Overview",
    },
    {
      id: 1,
      label: "Product/Service",
    },
    {
      id: 2,
      label: "Links",
    },
    {
      id: 3,
      label: "Funding",
    },
    {
      id: 4,
      label: "Team",
    },
    {
      id: 5,
      label: "Milestones",
    },
  ];
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const handleArrowClick = (direction) => {
    const numTabs = tabs.length;
    let scrollAmount = 0;

    if (direction === "left") {
      scrollAmount = -sliderRef.current.clientWidth;
    } else if (direction === "right") {
      scrollAmount = sliderRef.current.clientWidth;
    }

    sliderRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="w-full lg:px-6">
      <div>
        <div className="flex pt-7 lg:pt-20 flex-col lg:flex-row lg:justify-between lg:space-y-0 space-y-5 lg:items-center">
          <div>
            <div className="flex flex-row space-x-2 lg:space-x-0">
              <div className=" pt-2 basis-[25%] ">
                <div className="mr-3 h-[60px] w-[60px] lg:h-[110px] lg:w-[110px]">
                  <img
                    src={startupLogo}
                    className="h-[60px] w-[60px] lg:h-[110px] lg:w-[110px] rounded"
                    alt="Logo"
                  />
                </div>
              </div>
              <div className="md:pr-4 pr-2 basis-[75%] ">
                <div>
                  <div className="flex flex-row">
                    <div>
                      <div className="flex flex-col">
                        <div className="font-semibold text-[22px] lg:text-[29px]">
                          {startupName}
                        </div>
                        <div className="text-[14px] lg:text-[24px] font-light font-sans text-gray-500 ">
                          {tagline}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div>
              <div>
                <div className="flex flex-row space-x-4">
                  <div>
                    {" "}
                    <button>
                      <div className="px-2 hover:border-2 py-2 lg:w-[170px] text-[14px] lg:text-[17px] border-2 border-gray-200 font-medium text-black space-x-2 flex justify-center items-center rounded text-center">
                        <div>
                          <Menu>
                            <MenuButton
                              px={6}
                              py={2}
                              transition="all 0.2s"
                              borderRadius="md"
                            >
                              Visit <ChevronDownIcon />
                            </MenuButton>
                            <MenuList>
                              <MenuItem>
                                <Link
                                  fontWeight={5}
                                  href={`${website}`}
                                  isExternal
                                >
                                  Website <ExternalLinkIcon mx="2px" />
                                </Link>
                              </MenuItem>
                              {otherLinks.map((otherLink, index) => (
                                <div>
                                  <div key={index}>
                                    <MenuItem>
                                      <Link
                                        fontWeight={3}
                                        href={`${otherLink}`}
                                        isExternal
                                      >
                                        {otherLink}{" "}
                                        <ExternalLinkIcon mx="2px" />
                                      </Link>
                                    </MenuItem>
                                  </div>
                                </div>
                              ))}
                            </MenuList>
                          </Menu>
                        </div>
                      </div>
                    </button>
                  </div>
                  <div>
                    {" "}
                    <button>
                      <div className="px-3 py-4 bg-[#4169E1] w-[160px] text-[17px] font-normal text-white space-x-2 flex justify-center items-center rounded text-center">
                        <div>{upvoteCount} Upvotes</div>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col pt-9">
        <div className="basis-[72%] lg:border-r-2 lg:border-gray-200">
          <div>
            <div>
              {/* Startup Description*/}
              <div>
                {
                  <div>
                    {description !== undefined && description !== "" ? (
                      <div className="pt-3 text-slate-800 pr-2 lg:text-[19px] text-[14px]">
                        {description}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                }
              </div>

              {/* Startup Stage*/}
              <div>
                <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
                  {" "}
                  <div className="font-bold">Stage</div>
                </div>
                {
                  <div>
                    {stage !== undefined && stage !== "" ? (
                      <div className="pt-3 text-slate-600 pr-2 lg:text-[16px] text-[14px]">
                        {stage}
                      </div>
                    ) : (
                      <div></div>
                    )}
                  </div>
                }
              </div>

              {/* Startup Snapshot*/}
              <div>
                <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
                  {" "}
                  <div className="font-bold">Snapshot</div>
                </div>
              </div>

              {/*Startup Feedback*/}
              <div>
                <div className="flex pt-7 flex-row lg:space-x-3 justify-between lg:justify-normal">
                  {" "}
                  <div className="font-bold">Feedback and Comments</div>
                </div>
                <div className=" flex justify-center lg:justify-start pt-4">
                  <div>
                    <div className="py-3 px-3  text-[12.5px] lg:text-[15px] font-normal bg-slate-100 text-gray-700">
                      Feedback and comments from the community will soon appear
                      here, keep building!
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="basis-[38%]">
          <div className="pl-3 pt-10 lg:pt-0 font-bold text-[13px]">
            SIMILAR STARTUPS
            <div>
              <SimilarStartups
                similarCategories={selectedCategories}
                startupId={startupId}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StartupDetails;
