import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOption } from "../../../redux/FormSlice";

function Step3() {
  const selectedOption = useSelector((state) => state.form.selectedOption);

  const dispatch = useDispatch();

  const handleOptionChange = (event) => {
    dispatch(setSelectedOption(event.target.value)); // Update the selected option when the user makes a selection
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-between mb-6">
        <div>
          <label htmlFor="website" className="block text-[15px] text-gray-400">
            Can we share your startup on our social media channels?
          </label>
        </div>
      </div>
      <div className="flex flex-col space-y-4">
        <div>
          <label>
            <input
              type="radio"
              value="yes"
              checked={selectedOption === "yes"}
              onChange={handleOptionChange}
            />
            <span className="ml-3 ">Yes</span>
          </label>
        </div>

        <div>
          <label>
            <input
              type="radio"
              value="no"
              checked={selectedOption === "no"}
              onChange={handleOptionChange}
            />
            <span className="ml-3 ">No</span>
          </label>
        </div>
      </div>
    </div>
  );
}

export default Step3;
