import { useEffect, useState } from "react";
import { logo } from "../assets/images";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Avatar } from "@chakra-ui/avatar";
import {
  Menu,
  MenuButton,
  MenuDivider,
  MenuGroup,
  MenuItem,
  MenuList,
  Tooltip,
  useToast,
} from "@chakra-ui/react";
import { AnimatePresence } from "framer-motion";
import Modal from "./Modal";
import Login from "../pages/login/Login";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../firebase";

const NavbarM = () => {
  const [toggle, setToggle] = useState(false);
  const toast = useToast();
  const { logout, currentUser } = useAuth();
  const navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [userProfile, setUserProfile] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const close = () => setModalOpen(false);
  const open = () => setModalOpen(true);

  useEffect(() => {
    if (currentUser && currentUser.uid) {
      const unsubscribe = getUserProfile();
      return () => unsubscribe();
    }
  }, [currentUser]);

  const signIn = () => {
    modalOpen ? close() : open();
  };

  async function handleLogout() {
    try {
      await logout();
      navigate("/");
      toast({
        description: "Logout successfull",
        status: "success",
        duration: 9000,
        isClosable: true,
        position: "bottom",
      });
    } catch (error) {
      console.log("Logout error:", error);
      // Handle the error
    }
  }

  useEffect(() => {
    console.log(userProfile);
  }, [userProfile]);

  function getUserProfile() {
    const usersCollRef = collection(db, "users");
    const q = query(usersCollRef, where("userId", "==", currentUser.uid));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedProfile = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUserProfile(fetchedProfile[0]);
        setIsLoading(false);
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false);
      }
    );
    return unsubscribe;
  }

  return (
    <div className={`sm:px-6 px-[10px] z-50 flex justify-center items-center`}>
      <div className={` w-full`}>
        <nav className="w-full flex py-3 justify-between  items-center navbar">
          {/* Logo */}
          <Link to="/">
            <img
              src={logo}
              className="h-[45px] w-[45px] md:h-[55px] md:w-[55px]"
              alt="Logo"
            />
          </Link>

          {/* Desktop Navigation */}
          <ul className="list-none text-gray-600 font-normal pt-3 sm:flex hidden justify-center items-center flex-1">
            <li className="hover:text-blue-600    hover:cursor-pointer">
              <a>Startups</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>Hubs</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>Programs</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>Investors</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>Events</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>Community</a>
            </li>
            <li className="ml-11 hover:text-blue-600   hover:cursor-pointer">
              <a>About</a>
            </li>
          </ul>
          {/* Mobile Navigation */}
          <div className="sm:hidden ml-2 bg-white flex flex-1 justify-start items-center">
            {toggle ? (
              <div
                className="w-[28px] hidden h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[28px] h-[28px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </div>
            ) : (
              <div
                className="w-[28px] hidden h-[28px] object-contain"
                onClick={() => setToggle(!toggle)}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-[28px] h-[28px]"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                  />
                </svg>
              </div>
            )}

            {/* Sidebar */}
            <div
              className={`${
                !toggle ? "hidden" : "flex"
              } p-2 bg-white shadow-xl absolute z-50 top-20 right-0 mx-2 my-2 min-w-[240px] rounded-md sidebar`}
            >
              <ul className="list-none flex justify-start items-start flex-1 flex-col">
                <li className=" mb-3">
                  <a>
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#128640;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Startups</div>
                          <div className="text-[12px] text-gray-500">
                            Discover the Zambian startup ecosystem
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-4">
                  <a>
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#127970;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Hubs</div>
                          <div className="text-[12px] text-gray-500">
                            Hubs supporting startups
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-4">
                  <a>
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#128197;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Programs</div>
                          <div className="text-[12px] text-gray-500">
                            Accelerators, incubators
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li className="mb-4">
                  <a>
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#128188;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Investors</div>
                          <div className="text-[12px] text-gray-500">
                            Explore the investor landscape
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>

                <li className="mb-4">
                  <a>
                    {" "}
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#128100;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Community</div>
                          <div className="text-[12px] text-gray-500">
                            Network with founders
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-4">
                  <a>
                    {" "}
                    <div className="flex items-center flex-row space-x-3">
                      <div className="text-[15px]">&#10067;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">About</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
                <li className="mb-4">
                  <a>
                    {" "}
                    <div
                      onClick={handleLogout}
                      className="flex hover:bg-gray-300 items-center flex-row space-x-3"
                    >
                      <div className="text-[15px]">&#128682;</div>
                      <div>
                        <div className="flex flex-col">
                          <div className="font-semibold">Logout</div>
                        </div>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="navbar-end">
            {currentUser ? (
              <Menu>
                {" "}
                <MenuButton>
                  <Avatar
                    size="md"
                    name={currentUser.name}
                    src={
                      userProfile
                        ? userProfile.profilePhoto
                        : currentUser.photoURL
                    }
                  />{" "}
                </MenuButton>
                <MenuList width={{ base: 50, lg: 300 }}>
                  <Link to="/user-profile">
                    <MenuItem>
                      <div className="flex mb-3 flex-row space-x-1">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#5a5a5a"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
                            />
                          </svg>
                        </div>
                        <div className="text-[14px] lg:text-[15px]">
                          Profile
                        </div>
                      </div>
                    </MenuItem>
                  </Link>
                  <Link to="/startup-profile">
                    <MenuItem>
                      {" "}
                      <div className="flex mb-3 flex-row space-x-1">
                        <div>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="#5a5a5a"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M15.59 14.37a6 6 0 01-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 006.16-12.12A14.98 14.98 0 009.631 8.41m5.96 5.96a14.926 14.926 0 01-5.841 2.58m-.119-8.54a6 6 0 00-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 00-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 01-2.448-2.448 14.9 14.9 0 01.06-.312m-2.24 2.39a4.493 4.493 0 00-1.757 4.306 4.493 4.493 0 004.306-1.758M16.5 9a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z"
                            />
                          </svg>
                        </div>
                        <div className="text-[14px] lg:text-[15px]">
                          My startup
                        </div>
                      </div>
                    </MenuItem>
                  </Link>

                  <MenuItem onClick={handleLogout}>
                    {" "}
                    <div className="flex flex-row space-x-1">
                      <div>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="#5a5a5a"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                          />
                        </svg>
                      </div>
                      <div className="text-[14px] lg:text-[15px] text-gray-700">
                        Logout
                      </div>
                    </div>
                  </MenuItem>
                </MenuList>
              </Menu>
            ) : (
              <div
                onClick={signIn}
                className="py-3 md:text-[14px] text-[12px] sm:px-3 sm:py-2 font-medium px-5 rounded-md bg-gray-300 hover:bg-gray-300 "
              >
                Sign In
              </div>
            )}
          </div>
        </nav>
      </div>
      <AnimatePresence initial={false} mode="wait" onExitComplete={() => null}>
        {" "}
        {modalOpen && (
          <Modal
            modalOpen={modalOpen}
            handleClose={close}
            text={
              <Login
                signUpHeaderText="Sign In/Up"
                signUpBodyText="The Zambian entreprenurial ecosystem - startups, founders, investors,.."
                close={close}
              />
            }
          />
        )}
      </AnimatePresence>
    </div>
  );
};

export default NavbarM;
