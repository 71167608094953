import { useState, useRef, useEffect } from "react";
import "./index.css";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { db } from "../../firebase";
import { Link } from "react-router-dom";
import DetailsSlider from "./DetailsSlider";
import SimilarStartups from "./SimilarStartups";

function StartupSlider() {
  const [activeTab, setActiveTab] = useState(0);
  const sliderRef = useRef(null);
  const [startups, setStartups] = useState([]);
  const [similarStartups, setSimilarStartups] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Add isLoading state

  useEffect(() => {
    const unsubscribeAuthStateChange = onAuthStateChanged(getAuth(), (user) => {
      if (user) {
        // User is signed in, fetch startups
        const unsubscribeStartups = getStartups(user.uid);

        return () => {
          // Clean up the startups subscription when the component unmounts
          if (typeof unsubscribeStartups === "function") {
            unsubscribeStartups();
          }
        };
      } else {
        // User is signed out, handle it as needed
        setIsLoading(false);
      }
    });

    return () => {
      // Clean up the auth state change subscription when the component unmounts
      if (typeof unsubscribeAuthStateChange === "function") {
        unsubscribeAuthStateChange();
      }
    };
  }, []);

  function getStartups(currentUserId) {
    const startupsCollRef = collection(db, "startups");
    const q = query(startupsCollRef, where("userId", "==", `${currentUserId}`));
    const unsubscribe = onSnapshot(
      q,
      (snapshot) => {
        const fetchedStartups = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setStartups(fetchedStartups);
        setIsLoading(false); // Update isLoading state when data is fetched
      },
      (error) => {
        console.log(error.message);
        setIsLoading(false); // Update isLoading state in case of an error
      }
    );

    return unsubscribe;
  }

  const handleTabClick = (startupId) => {
    setActiveTab(startupId);
  };

  const handleArrowClick = (direction) => {
    const numTabs = startups.length;
    let scrollAmount = 0;

    if (direction === "left") {
      scrollAmount = -sliderRef.current.clientWidth;
    } else if (direction === "right") {
      scrollAmount = sliderRef.current.clientWidth;
    }

    sliderRef.current.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  return (
    <div className="bg-white ">
      <div className="flex justify-center items-center">
        <div className=" lg:w-[1100px]  w-screen">
          <div className="conta my-auto w-full bg-slate-200 lg:w-[1100px] relative">
            <div className="">
              <ul
                className="flex bg-white md:items-center noSelect outline-none flex-row cursor-pointer text-gray-900 font-sans space-x-[19px] font-medium whitespace-nowrap px-3 lg:px-1 md:space-x-[26px] py-[9px]  m-0 "
                ref={sliderRef}
              >
                {startups.map((startup, index) => (
                  <li
                    key={startup.id}
                    className={`bg-white pb-2 ${
                      startup.id === startups[0].id ? "" : ""
                    }`}
                  >
                    {startup.icon && startup.icon}
                    <div className="pt-1">
                      {" "}
                      <div
                        href="#"
                        className={`py-[4px] lg:py-[8px] noSelect font-medium  text-[13px] md:text-[14px] inline-block select-none ${
                          index === activeTab
                            ? " text-gray-800 font-extrabold"
                            : " text-gray-300  hover:bg-gray-50 "
                        }`}
                        onClick={() => handleTabClick(index)}
                      >
                        {startup.startupName}
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="px-[6px] lg:px-0">
            <div className="flex hover:cursor-pointer flex-row items-center space-x-1">
              <div>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 24 24"
                  fill="white"
                  strokeWidth={0.5}
                  stroke="white"
                  className="w-6 h-6"
                >
                  <path
                    fill="#5a5a5a"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              </div>
              <div className="text-[13px] md:text-[14px] text-gray-800">
                Edit page
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="lg:border-gray-200 border-gray-100  border-b-2 pt-2"></div>

      <div className="md:px-[45px] px-[7px] pt-[15px] lg:pt-[35px] overflow-x-hidden">
        {startups.map((startup, index) => {
          if (index === activeTab) {
            return (
              <div className="flex lg:justify-center" key={startup.id}>
                <div className=" w-full lg:h-[500px]  lg:w-[1100px] ">
                  <div className="flex flex-col">
                    <div>
                      <div className="flex flex-col lg:flex-row lg:justify-between lg:space-y-0 space-y-5 lg:items-center">
                        <div>
                          <div className="flex flex-row items-center space-x-2 lg:space-x-0">
                            <div className="md:mr-3 pt-2 basis-[20%]">
                              <img
                                src={startup.startupLogo}
                                className="h-[60px] w-[60px] lg:h-[100px] lg:w-[100px] rounded"
                                alt="Logo"
                              />
                            </div>
                            <div className="md:pr-4 pr-2 basis-[80%]">
                              <div>
                                <div className="flex flex-row">
                                  <div>
                                    <div className="flex flex-col">
                                      <div className="font-semibold text-[22px] lg:text-[29px]">
                                        {startup.startupName}
                                      </div>
                                      <div className="text-[14px] lg:text-[19px] font-sans text-gray-500 ">
                                        {startup.tagline}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div>
                          <div>
                            <div>
                              <div className="flex flex-row space-x-4">
                                <div>
                                  {" "}
                                  <a
                                    href={`${startup.website}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <button>
                                      <div className="px-2 hover:border-2 py-4 lg:w-[170px] text-[14px] lg:text-[17px] border-2 border-gray-200 font-medium text-black space-x-2 flex justify-center items-center rounded text-center">
                                        <div>Website</div>
                                      </div>
                                    </button>
                                  </a>
                                </div>
                                <div>
                                  {" "}
                                  <button>
                                    <div className="px-3 py-4 bg-[#4169E1] w-[160px] text-[17px] font-normal text-white space-x-2 flex justify-center items-center rounded text-center">
                                      <div>{startup.upvoteCount} Upvotes</div>
                                    </div>
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pt-8">
                      <div className="flex flex-col lg:flex-row">
                        <div className="basis-[75%] lg:border-r-2 lg:border-gray-200">
                          {" "}
                          <DetailsSlider
                            startup={startup}
                            startupId={startup.id}
                          />
                        </div>
                        <div className="basis-[34%]">
                          <div className="pl-3 pt-4 lg:pt-0 font-bold text-[13px]">
                            SIMILAR STARTUPS
                            <div>
                              <SimilarStartups
                                similarCategories={startup.selectedCategories}
                                startupId={startup.id}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          }
          return null; // Return null for startups that are not active
        })}
      </div>
    </div>
  );
}

export default StartupSlider;
